import React, { useEffect, useState } from 'react';
import styles from './ShoppingBagCard.module.scss'
import { RiArrowRightSLine } from '@remixicon/react';
import deleteBinIcon from "../../assets/icons/deleteBin.svg";
import ShoppingBagCardItem from '../shoppingBagCardItem/ShoppingBagCardItem';
import { Helper } from '../../utils/Helper';
import { Ellipsis, Modal } from 'antd-mobile';
import { useDispatch } from 'react-redux';
import { Log } from '../../utils/Log';
import ItemSet from '../../utils/entity/ItemSet';
import { LocalStorage } from '../../utils/LocalStorage';
import { useTranslation } from 'react-i18next';
import { reRender } from '../../redux/tickPageSlice';
import { setData } from '../../redux/tickTempUniformSet';
import { MasterDataService } from '../../service/apis/MasterDataService';
import Bag from '../../utils/entity/Bag';
import Item from '../../utils/entity/Item';

const TAG = "ShoppingBagCard";

const ShoppingBagCard = ({ 
  setInfo,
  isEditMode,
  setIsEditMode,
  setIndex,
  setChooseUniformStackVisible,
}) => {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [itemSet, setItemSet] = useState(setInfo);
  const [items, setItems] = useState([]);

  const mapMasterDataToItems = async () => {
    const masterDataRes = await MasterDataService.get();
    let masterData;
    if (masterDataRes.success) masterData = masterDataRes.data;
    const callback = (value) => {
      const item = new Item(value);
      if (masterData["color_code"][item.color]) {
        value.color = masterData["color_code"][item.color]?.[Helper.langShortForm()]
      }
    }

    const set = new ItemSet(setInfo);
    setItems(set.toArray(callback));
  }

  useEffect(() => {
    mapMasterDataToItems();
  }, [setInfo])

  const onDeleteSetClick = (e) => {
    e.stopPropagation();

    Modal.confirm({
      content: t("t_confirmDelete"),
      confirmText: t("t_yes"),
      cancelText: t("t_no"),
      onConfirm: () => {
        const bag = new Bag(LocalStorage.get("shoppingBag").toObject());
        bag.remove(setIndex);
        LocalStorage.set("shoppingBag", JSON.stringify(bag.itemSets));
        setIsEditMode(false)
        dispatch(reRender())
      },
      onCancel: () => {
        Modal.clear();
      }
    })
  }

  const onCardTitleClick = () => {
    setChooseUniformStackVisible(true);
    dispatch(setData({ ...setInfo, editIndex: setIndex, goToOptionIndex: -1 }));
  }

  const onCardItemClick = (style) => {
    setChooseUniformStackVisible(true);
    let index = setInfo.options.findIndex(item => item.items.some(subItem => subItem.style === style));
    dispatch(setData({ ...setInfo, editIndex: setIndex, goToOptionIndex: index }));
  }

  return (
    <>
      <div className={styles.cardContainer}>
        <div className={styles.cardTitleContainer} onClick={onCardTitleClick}>
          <div className={styles.cardTitleWithArrow}>
            <Ellipsis content={itemSet[Helper.toLangKey("name")]}/>
            <RiArrowRightSLine color={"var(--adm-color-text-secondary)"} />
          </div>
          {
            isEditMode ? 
            <img src={deleteBinIcon} alt="delete-icon" className={styles.deleteIcon} onClick={onDeleteSetClick}/>
            :
            null
          }
        </div>

        {
          items.map((item, index) => {
            return (
              <ShoppingBagCardItem 
                data={item} 
                key={`${item.sku}_${index}`} 
                isEditMode={isEditMode} 
                setIsEditMode={setIsEditMode}
                setIndex={setIndex}
                itemIndex={index}
                onCardItemClick={onCardItemClick}
              />
            )
          })
        }
      </div>
    </>
  )
}

export default ShoppingBagCard