import i18n from "../i18n";

export const orderStatus = {
  OPEN: {
    label: i18n.t("t_waitForPayment"),
    filterType: '',
  },
  PAY_SUCCESS: {
    label: i18n.t("t_paymentSuccess"),
    filterType: 'waitForDelivery',
  },
  PAY_FAILURE: {
    label: i18n.t("t_paymentFailure"),
    filterType: '',
  },
  WAIT_FOR_PICK_UP: {
    label: i18n.t("t_paymentSuccess"),
    filterType: 'readyForPickup',
  },
  COMPLETED: {
    label: i18n.t("t_transactionComplete"),
    filterType: '',
  },
  CANCEL: {
    label: i18n.t("t_transactionCancellation"),
    filterType: '',
  }
}