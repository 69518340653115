import ItemStyle from "./ItemStyle";

export default class Item {
  constructor(item, style) {
    this.sku = item.sku;
    this.size = item.size;
    this.qty = item.qty;
    this.price = item.price;
    
    // e.g SKTSD020B2032
    this.school_code = this.sku.slice(0, 3);
    this.season = this.sku[3];
    this.category = this.sku[4];
    this.item_num = this.sku.slice(5, 8);
    this.color = item.color ? item.color : this.sku.slice(8, 10);

    if (item.style) {
      this.style = new ItemStyle(item.style);
    } else if (style) {
      this.style = new ItemStyle(style);
    }
    delete this.style.items
  }
}

export class CheckoutItem {
  constructor(item) {
    this.qty = item.qty;
    this.unit_amount = Number(item.price);
    this.subtotal_amount = item.price * item.qty;
  }
}