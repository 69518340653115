import React, {useState} from 'react';
import styles from './ChooseUniformItem.module.scss'
import { useDispatch } from 'react-redux';
import { List } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { RiCheckboxCircleFill, RiCheckboxBlankCircleLine } from '@remixicon/react';

const ChooseUniformItem = ({ 
  image,
  title,
  size = '',
  chooseText,
  required = true,
  onClick,
  chooseStyle = false,
  isSelected = false,
  color
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <>
      <List style={{ marginTop: chooseStyle? "0px" : "6px" }}>
        <div className={styles.test} onClick={onClick}>
          <List.Item  onClick={() => {}}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
              {
                chooseStyle &&
                (isSelected 
                  ? <RiCheckboxCircleFill color={"var(--adm-color-primary)"} />
                  : <RiCheckboxBlankCircleLine color={"var(--ion-color-medium-tint)"} />
                )
              }
              <div style={{ width: "15%", height: "15%", margin: "0 5px", display: "flex", alignItems: "center"  }}>
                {
                  image ?
                  <img src={image[0]} style={{ width: "100%", height: "100%" }} /> :
                  <div style={{ width: "100%", height: "6vh" }}></div>
                }
              </div>
              <div>
                {
                  title &&
                  <div>{`${title} ${color ? `- ${color}` : ''}`}</div>
                }
                {
                  size &&
                  size.split(",").map((item, index) => {
                    return <div key={index} style={{ fontSize: "12px", color: "var(--adm-color-primary)"}}>{item}</div>
                  })
                }
                {
                  !chooseStyle && !size &&
                  <div className={styles.chooseUniformStyleText}>{t("t_select")} {chooseText} {!required ? `(${t("t_optional")})` : ""}</div>
                }
                
              </div>
            </div>

          </List.Item>

        </div>
      </List>
    </>
  )
}

export default ChooseUniformItem;