import { useEffect, useState } from "react";
import { App } from "antd";
// import PaymentSuccess from "../paymentSuccess/PaymentSuccess";
import { LocalStorage } from "../../utils/LocalStorage";
import { useNavigate, useParams } from "react-router";
import { UtilityService } from "../../service/apis/UtilityService";
import { useTranslation } from "react-i18next";
import { Log } from "../../utils/Log";
// import PaymentFailure from "../paymentFailure/PaymentFailure";
import Response from "../../utils/Response";
import { Result, ResultPage, Toast } from "antd-mobile";
import { Helper } from "../../utils/Helper";

const TAG = "Payment";

export const Payment = () => {
  const { t } = useTranslation();
  const { code } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);

  const processPayment = async () => {
    // const bag = new Bag(LocalStorage.get("shoppingBag").toObject());
    // const items = bag.toMap();
    // const body = {
    //   // TO ASK: total amount include transaction fee ?
    //   total_amount: bag.total().price + transactionFee,
    //   payment_gateway: paymentMethod,
    //   delivery_method: deliveryMethod, //sf_cod,sf_locker,pickup_point
    //   delivery_address: undefined,
    //   pickup_point_id: undefined,
    //   sf_locker_code: undefined,
    //   items
    // }
    // Log(TAG, body)

    // setTimeout(() => {
    //   setLoading(false)
    // }, 2000)

    // const res = await TransactionService.checkout(body);
    // setStatus(res.success)
    // set
    // setResponse(res)    
  }

  const decode = async () => {
    Log(TAG, code)
    const res = await UtilityService.get(code);
    new Response(res).handle((data) => {
      Log(TAG, data)
      // let temp = {    
      //   "tran_id":"1000000011",
      //   "amount":"500",
      //   "status":"PAY_SUCCESS" //CANCAL,PAY_FAILURE}
      // }
      if (!data["tran_id"] || !data["total_amount"] || !data["status"]) { 
        navigate('/accessDenied');
        return;
      }
      setResponse(data);
      Toast.show({ icon: 'loading', content: t("t_paymentProcessing") })
      setTimeout(() => {
        Toast.clear()
        setLoading(false)
      }, 1000)
    }, () => {
      navigate('/accessDenied');
    })
  }

  const render = () => {
    const details = [{ label: t("t_orderNum"), value: response["tran_id"] }]
    if (response.status === "PAY_SUCCESS") {
      return <PaymentSuccess totalAmount={response["total_amount"]} details={details}/>
    } else if (response.status === "PAY_FAILURE") {
      return <PaymentFailure totalAmount={response["total_amount"]} details={details}/>
    } else if (response.status === "CANCELLED") {
      return <PaymentCancel totalAmount={response["total_amount"]} details={details}/>
    }
  }

  useEffect(() => {
    if (!code) navigate('/accessDenied');
    decode();
  }, [])

  return (
   <>
    {/* {
      loading ? (
        <App style={{ backgroundColor: "var(--app-block-content-background-color)" }}>
        </App>
      ) : (
        // <PaymentResult paymentStatus={response}/>
        <PaymentSuccess />
      )
    } */}
    {
      !loading && response ? render() : <App style={{ backgroundColor: "var(--app-block-content-background-color)" }}></App>
    }
   </> 
  )

} 

const PaymentSuccess = ({
  totalAmount,
  details
}) => {
  
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    LocalStorage.set("shoppingBag", JSON.stringify([]));
  }, [])

  return (
    <App>
      <ResultPage
        className={'paymentSuccess'}
        status={'success'}
        title={
          <div style={{ fontSize: 15 }}>
            { t("t_paymentSuccess") }
          </div>
        }
        description={
          <>
            <span style={{ fontSize: 22, color: '#ffffff', marginRight: 4 }}>
              HK$
            </span>
            <span style={{ fontSize: 48, color: '#ffffff' }}>{Helper.formatNumberWithCommas(totalAmount)}</span>
          </>
        }
        details={details}
        secondaryButtonText={t("t_buyMore")}
        primaryButtonText={t("t_reviewOrder")}
        onSecondaryButtonClick={() => { navigate("/main/uniform") }}
        onPrimaryButtonClick={() => { navigate("/main/order") }}
      >
        <Result
          status={"success"}
          title={t("t_paymentSuccessCardHeader")}
          description={t("t_paymentSuccessMsg")}
        />
      </ResultPage>
    </App>
  )
}

const PaymentFailure = ({
  totalAmount,
  details
}) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    LocalStorage.set("shoppingBag", JSON.stringify([]));
  }, [])

  return (
    <App>
      <ResultPage
        className={"paymentFailure"}
        status={"error"}
        title={
          <div style={{ fontSize: 15 }}>
            {t("t_paymentFailure")}
          </div>
        }
        description={
          <>
            <span style={{ fontSize: 22, color: '#ffffff', marginRight: 4 }}>
              HK$
            </span>
            <span style={{ fontSize: 48, color: '#ffffff' }}>{Helper.formatNumberWithCommas(totalAmount)}</span>
          </>
        }
        details={details}
        secondaryButtonText={ t("t_backToHomepage")}
        primaryButtonText={t("t_retryPayment")}
        onSecondaryButtonClick={() => { navigate("/main/uniform") }}
        onPrimaryButtonClick={null}
      >
        <Result
          status={"error"}
          title={t("t_transactionCancellation")}
          description={t("t_paymentFailureMsg")}
        />
      </ResultPage>
    </App>
  )
}

const PaymentCancel = ({
  totalAmount,
  details
}) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   LocalStorage.set("shoppingBag", JSON.stringify([]));
  // }, [])

  return (
    <App>
      <ResultPage
        className={"paymentCancel"}
        status={"error"}
        title={
          <div style={{ fontSize: 15 }}>
            {t("t_paymentCancel")}
          </div>
        }
        description={
          <>
            <span style={{ fontSize: 22, color: '#ffffff', marginRight: 4 }}>
              HK$
            </span>
            <span style={{ fontSize: 48, color: '#ffffff' }}>{Helper.formatNumberWithCommas(totalAmount)}</span>
          </>
        }
        details={details}
        secondaryButtonText={ t("t_backToHomepage")}
        primaryButtonText={t("t_reviewOrder")}
        onSecondaryButtonClick={() => { navigate("/main/uniform") }}
        onPrimaryButtonClick={() => { navigate("/main/order") }}
      >
        <Result
          status={"info"}
          title={t("t_transactionCancellation")}
          description={""}
        />
      </ResultPage>
    </App>
  )
}

export default PaymentFailure