import React, { useEffect } from "react";
import "./themes/App.scss";
import AppRoute from "./routes/AppRoute";
import Theme from "./components/theme/Theme";
import "@fontsource/noto-sans-hk";
import "@fontsource/noto-sans-hk/400.css";
import i18n from "./i18n";
import { Log } from "./utils/Log";
import { LocalStorage } from "./utils/LocalStorage";
import { useSelector } from "react-redux";
import { Modal } from "antd-mobile";

function App() {

  const pageState = useSelector((state) => state.tickPage);

  useEffect(() => {
    const isPWAMode =
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone;

    const doc = document.documentElement;
    if (isPWAMode) {
      doc.style.setProperty("--tick-doc-height", `98%`);
    } else {
      doc.style.setProperty("--tick-doc-height", `100%`);
    }

    // Language
    const lang = LocalStorage.get("lang").toString();
    if (lang) {
      Log("System Language", lang);
      i18n.changeLanguage(lang);
    } else {
      Log("System Language", "tc_hk");
      LocalStorage.set("lang", "tc_hk")
    }

  }, [pageState.render]);

  return (
    <Theme>
      <AppRoute />
    </Theme>
  );
}

export default App;
