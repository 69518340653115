import { configureStore } from "@reduxjs/toolkit";
import tickModalSlice from "./tickModalSlice";
import tickPageSlice from "./tickPageSlice";
import tickStackSlice from "./tickStackSlice";
import tickTempUniformSetSlice  from "./tickTempUniformSet";

export default configureStore({
  reducer: {
    tickModal: tickModalSlice,
    tickPage: tickPageSlice,
    tickStack: tickStackSlice,
    tickTempUniformSet: tickTempUniformSetSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ["payload.callback", "payload"],
        ignoredPaths: ["tickModal.params.callback", "tickTempUniformSet.set"],
      },
    }),
});
