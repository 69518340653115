import { decrypt, encrypt } from "./Crypto";

class JsonValue {

  value;

  toString() { return this.value }

  toInt() { return this.value ? parseInt(this.value) : null }; 

  toObject() { return this.value ? JSON.parse(this.value) : null };

  isNull() { 
    return !this.value || this.value === "null" || this.value === "undefined" ? true : false;
  }

  constructor (value) {
    this.value = value;
  }

}

export class LocalStorage {

  static get(key) { 
    let value = localStorage.getItem(key) ? decrypt(localStorage.getItem(key)) : null;
    value = JSON.parse(value)?.value
    return new JsonValue(value);
  }

  static set(key, value) {
    localStorage.setItem(key, encrypt(JSON.stringify({ value })));
  }

  static remove(key) {
    localStorage.removeItem(key);
  }

}