import request from "../request";

export class TransactionService {
  
  static get = async () => {
    return await request.get(`/trans`);
  };

  static checkout = async (body) => {
    return await request.post(`/checkout`, body);
  };

}