import { App } from "antd"
import { AutoCenter, Button, ErrorBlock, Space } from "antd-mobile"
import Logo from "../../assets/images/logo.png";
import styles from "./PageNotFound.module.scss"
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { LocalStorage } from "../../utils/LocalStorage";
import { useEffect } from "react";

const PageNotFound = () => {
  const { t } = useTranslation();
  const schoolCode = LocalStorage.get("tempSchoolCode")
  const navigate = useNavigate();

  useEffect(() => {
    if (schoolCode.isNull()) return;

    LocalStorage.remove("schoolCode");
    LocalStorage.remove("tempSchoolCode");
  }, [])

  const onClick = () => {
    navigate(`/code/${schoolCode.toString()}`);

  }

  return (
    <App>
      <div className={styles.pageNotFoundContainer}>
        <div>
          <Space direction="vertical">
            <ErrorBlock
              image={Logo}
              status='empty'
              title={t("t_pageNotFound")}
              description=""
            />
            { !schoolCode.isNull() && (
              <AutoCenter> 
                <Button color="primary" onClick={onClick}>{t("t_backToHomepage")}</Button>
              </AutoCenter>
            )}
          </Space>
        </div>
      </div>
    </App>
  )
}

export default PageNotFound;