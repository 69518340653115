import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Stack from "../Stack";
import styles from './ChooseUniformSizeStack.module.scss'
import { useSelector, useDispatch } from 'react-redux';
import { Helper } from '../../../utils/Helper';
import { Collapse, Button, Selector, Form, CheckList, Swiper } from "antd-mobile";
import { RiVolumeUpLine } from '@remixicon/react';
import UniformSizeItem from "../../uniformSizeItem/UniformSizeItem";
import { ItemService } from "../../../service/apis/ItemService";
import { updateSelectedData, removeSelectedData } from "../../../redux/tickTempUniformSet";
import { pop } from "../../../redux/tickStackSlice";
import { Log } from "../../../utils/Log";

const TAG = "ChooseUniformSizeStack"

const ChooseUniformSizeStack = ({
  visible,
  setVisible,
  setChooseStyleVisible,
  style,
}) => {
  const { t } = useTranslation();
  const [productData, setProductData] = useState({});
  const [sizeItemDisable, setSizeItemDisable] = useState(false);
  const [reduxData, setReduxData] = useState({});
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const tempUniformSetState = useSelector((state) => state.tickTempUniformSet.set);
  const stackStatus = useSelector((state) => state.tickStack.status);
  const [collapseActiveKey, setCollapseActiveKey] = useState([])

  // useEffect(() => {
  //   return function() {
  //     setProductData({});
  //   }
  // }, [visible])

  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        setProductData({});
        setCollapseActiveKey([])
      }, 500)
      return
    }
    fetchByStyleCode();
    getSelectedDataFromRedux();
  }, [style])

  const fetchByStyleCode = () => {
    if (!style) return
    setProductData({});
    ItemService.get(style).then((res) => {
      // TODO: remove
      for (let i = 0; i < res.data.size_breakdown.length; i++) {
        res.data.size_breakdown[i].qty = 5;
      }
      // TODO: remove
      setProductData(res.data);
    })
  }

  useEffect(() => {
    if (Object.keys(reduxData).length > 0) {
      form.setFieldsValue(reduxData.selected.qty);
    } else {
      form.resetFields();
    }
  }, [reduxData])



  const getSelectedDataFromRedux = () => {
    if (tempUniformSetState && Array.isArray(tempUniformSetState.options)) {
      outerLoop:
      for (let option of tempUniformSetState.options) {
        for (let item of option.items) {
          if (item.style === style && item.hasOwnProperty('selected')) {
            setReduxData(item);
            break outerLoop;
          } else {
            setReduxData({});
          }
        }
      }
    }
  }

  const onSubmit = () => {
    const values = form.getFieldsValue();
    if (values?.isNoNeed?.length > 0) {
      // noNeed is selected
      dispatch(removeSelectedData({
        style: productData.style
      }));
      setReduxData({});
    } else {
      // change all undefined to 0
      const newData = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [key, value === undefined ? 0 : value])
      );

      delete newData.isNoNeed

      if (Object.values(newData).every(value => value === 0)) {
        // all size are zero, remove isSelect object from redux
        dispatch(removeSelectedData({
          style: productData.style
        }));
        setReduxData({});
      } else {
        // update to redux
        dispatch(updateSelectedData({
          style: productData.style,
          selected: {
            photo: productData.item_images,
            qty: newData,
            sizeBreakdown: productData.size_breakdown,
          }
        }));
      }
    }
    // form.resetFields();
    // setVisible(false);
    // setChooseStyleVisible(false);
    dispatch(pop());
    if (stackStatus.chooseUniformStyle) dispatch(pop());

    // setSizeItemDisable(false);
  }

  const onNoNeedClick = () => {
    if (form.getFieldValue('isNoNeed').length > 0) {
      // setSizeItemDisable(true);
      const values = form.getFieldsValue();
      const formData = {};
      Object.keys(values).forEach(item => {
        if (item === "isNoNeed") return
        formData[item] = 0;
      });
      form.setFieldsValue(formData);
    }
  }

  const onCollapseChange = (e) => {
    setCollapseActiveKey(e)
  }

  const onQtyChange = () => {
    form.setFieldValue("isNoNeed", [])
  }

  return (
    <Stack
      title={productData[Helper.toLangKey("name")]}
      setVisible={setVisible}
      visible={stackStatus.chooseUniformSize}
    >
      <div style={{ width: "100%" }}>
        <div className={styles.uniformContainer}>
          {Object.keys(productData).length > 0 &&
            <Swiper autoplay loop autoplayInterval={5000}>
              {
                productData.item_images.map((img, index) =>
                  <Swiper.Item key={index}>
                    <img src={img} alt="uniform-image" className={styles.mainUniformPic} />
                  </Swiper.Item>
                )
              }
            </Swiper>
          }
        </div>
        <Collapse className={styles.collapseContainer} onChange={onCollapseChange} activeKey={collapseActiveKey}>
          <Collapse.Panel key='1' title={t("t_washMark")}>
            {Object.keys(productData).length > 0 &&
              productData.care_label_images.map((img, i) =>
                <img key={`washMark_${i}`} src={img} alt="uniform-image" className={styles.mainUniformPic} />
              )
            }
          </Collapse.Panel>
          <Collapse.Panel key='2' title={t("t_sizeChart")}>
            {Object.keys(productData).length > 0 &&
              productData.size_spec_images.map((img, i) =>
                <img key={`sizeSpec_${i}`} src={img} alt="uniform-image" className={styles.mainUniformPic} />
              )
            }
          </Collapse.Panel>
        </Collapse>
        <div className={styles.hintContainer}>
          <RiVolumeUpLine style={{ marginRight: "5px" }} />
          <div style={{ color: "var(--adm-color-primary)" }}>{productData[Helper.toLangKey("remark")] || '-'}</div>
        </div>
        <Form form={form}>
          {
            productData?.size_breakdown?.map((item, index) => {
              return (
                <UniformSizeItem
                  onChange={onQtyChange}
                  key={`UniformSizeItemIndex_${index}`}
                  size={item.size[Helper.toLangKey("name")]}
                  price={item.price}
                  outOfStock={item.qty === null}
                  maxQty={item.qty}
                  disabled={sizeItemDisable}
                />
              )
            })
          }
          <div className={styles.noNeed}>
            <Form.Item name='isNoNeed'>
              <CheckList>
                <CheckList.Item value='noNeed' onClick={onNoNeedClick}>
                  {t("t_noNeed")}
                </CheckList.Item>
              </CheckList>
            </Form.Item>
          </div>
        </Form>
        <div style={{ margin: "10px 10px", paddingBottom: "10px" }}>
          <Button
            block
            type='submit'
            color='primary'
            size='large'
            onClick={() => {
              onSubmit();
            }}>
            {t('t_complete')}
          </Button>
        </div>
      </div>
    </Stack>
  );
};

export default ChooseUniformSizeStack;
