import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Stack from "../Stack";
import ChooseUniformItem from "../../chooseUniformItem/ChooseUniformItem";
import { Helper } from '../../../utils/Helper';
import ChooseUniformSizeStack from "../chooseUniformSize/ChooseUniformSizeStack";
import { useDispatch, useSelector } from "react-redux";
import { pop, push } from "../../../redux/tickStackSlice";

const TAG = "ChooseStylesStack";

const ChooseStyleStack = ({
  visible,
  setVisible,
  chooseStyleStackData,
}) => {
  const { t } = useTranslation();
  // const [chooseUniformSizeVisible, setChooseUniformSizeVisible] = useState(false);
  const [style, setStyle] = useState('');

  const dispatch = useDispatch();
  const stackStatus = useSelector((state) => state.tickStack.status);

  // useEffect(() => {
  //   if (chooseStyleStackData?.items?.length === 1 && visible) {
  //     // setChooseUniformSizeVisible(true);
  //     dispatch(push("chooseUniformSize"))
  //     setStyle(chooseStyleStackData.items[0].style);
  //   }
  // }, [visible])

  // useEffect(() => {
  //   if (!stackStatus.chooseUniformSize) {
  //     // setVisible(false);
  //     dispatch(pop())
  //   }
  // }, [stackStatus.chooseUniformSize]);

  return (
    <Stack
      title={Object.keys(chooseStyleStackData).length === 0 ? '' :chooseStyleStackData.category[Helper.toLangKey()]}
      setVisible={setVisible}
      visible={visible}
    >
      <div style={{ width: "100%" }}>
        {
          chooseStyleStackData?.items?.map((item, index) => {
            const isSelected = item.hasOwnProperty('selected');
            return (
              <ChooseUniformItem 
                chooseStyle
                key={index}
                title={item[Helper.toLangKey('name')]} 
                color={item.style_info.color[Helper.toLangKey("name")]}
                onClick={() => {
                  setStyle(item.style);
                  // setChooseUniformSizeVisible(true);
                  dispatch(push("chooseUniformSize"))
                }}
                image={item.item_images}
                isSelected={isSelected}
              />
            )
          })
        }
      </div>
      <ChooseUniformSizeStack 
        visible={stackStatus.chooseUniformSize} 
        // setVisible={setChooseUniformSizeVisible} 
        setChooseStyleVisible={setVisible}
        style={style}
      />
    </Stack>
  );
};

export default ChooseStyleStack;
