import axios from "axios";
import qs from "qs";
import { endpoint } from "../configs/env";
import { API_KEY } from "../configs/auth";
import Response from "../utils/Response";
import { Helper } from "../utils/Helper";
import { Modal } from "antd-mobile";
import { LocalStorage } from "../utils/LocalStorage";

// 创建axios实例
const axiosInstance = axios.create({
  baseURL: endpoint.apiUrl,
  timeout: 60000 * 10,
  headers: { "Content-Type": "application/json;charset=UTF-8" },
  // withCredentials: true,
});
axiosInstance.defaults.timeout = 60000 * 10;

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    switch (error.response.status) {
      case 400:
        // Message.error(error.response.data.message)
        break;
      case 401:
        // 登录过期
        console.log("401");
        localStorage.clear();
        // window.location.href = "./login";
        break;
      case 403:
        // 无权限
        // Message.error("no permission")
        break;
      case 422:
        // Message.error(error.response.data.message)
        break;
      case 500:
        // 服务器错误处理
        // Message.error("Internal Server Error")
        break;
      default:
      // Message.error('Network Error')
    }
  }
  return Promise.reject(error);
};

// 请求拦截处理器
axiosInstance.interceptors.request.use((config) => {
  // qs是axios自带的序列化参数方式
  if (
    config.headers &&
    config.headers["Content-Type"] &&
    config.headers["Content-Type"].includes("application/x-www-form-urlencoded")
  ) {
    config.params = qs.stringify(config.params);
  }

  // Add x-api-key header
  config.headers["x-api-key"] = API_KEY;
 
  // Add school code header if schoolCode exists in the localStorage
  const schoolCode = LocalStorage.get("schoolCode");
  if (!schoolCode.isNull()) {
    config.headers["x-school-code"] = schoolCode;
  } 

  // Add user token header if token exists in the localStorage
  const token = LocalStorage.get("token");
  if (!token.isNull()) {
    config.headers["x-user-token"] = token;
  } 

  return config;
}, errorHandler);

// 响应拦截处理器
axiosInstance.interceptors.response.use((response) => {
  return response;
}, errorHandler);

function request(config) {
  return new Promise((resolve, reject) => {
    axiosInstance
      .request(config)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function post(url, data, config) {
  return new Promise((resolve, reject) => {
    request({
      url: url,
      method: "POST",
      data: data,
      ...config,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function get(url, params, config) {
  const tempConfig = { ...config };
  return new Promise((resolve, reject) => {
    request({
      url: url,
      method: "GET",
      params,
      ...tempConfig,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function put(url, data, config) {
  return new Promise((resolve, reject) => {
    request({
      url: url,
      method: "PUT",
      data: data,
      ...config,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function del(url, data, config) {
  return new Promise((resolve, reject) => {
    request({
      url: url,
      method: "DELETE",
      data: data,
      ...config,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}



export default {
  post,
  get,
  put,
  del
};
