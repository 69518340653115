import React, { useState, memo, useEffect } from 'react';
import styles from './ShoppingBagCardItem.module.scss'
import deleteBinIcon from "../../assets/icons/deleteBin.svg";
import { LocalStorage } from '../../utils/LocalStorage';
import { Helper } from '../../utils/Helper';
import { Ellipsis, Modal } from 'antd-mobile';
import { RiArrowDropDownLine } from '@remixicon/react';
import { useDispatch } from 'react-redux';
import { Log } from '../../utils/Log';
import Item from '../../utils/entity/Item';
import Bag from '../../utils/entity/Bag';
import { useTranslation } from 'react-i18next';
import { reRender } from '../../redux/tickPageSlice';

const TAG = "ShoppingBagCardItem"

const ShoppingBagCardItem = ({ 
  data,
  setIndex,
  itemIndex,
  isEditMode,
  setIsEditMode,
  onCardItemClick,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [item, setItem] = useState(new Item(data));

  useEffect(() => {
    Log(TAG, data)
    setItem(new Item(data));
  }, [data]);
  
  const onDeleteItemClick = () => {
    Log(TAG, item)
    Modal.confirm({
      content: t("t_confirmDelete"),
      confirmText: t("t_yes"),
      cancelText: t("t_no"),
      onConfirm: () => {
        const bag = new Bag(LocalStorage.get("shoppingBag").toObject());
        bag.removeItem(data.sku, setIndex);
        if (bag.itemSets.length <= 0) setIsEditMode(false)
        Log(TAG, bag)
        LocalStorage.set("shoppingBag", JSON.stringify(bag.itemSets));
        dispatch(reRender())
      },
      onCancel: () => {
        Modal.clear();
      }
    })
  }

  const onItemClick = () => {
    onCardItemClick(data.sku.substring(0, 10));
  }
  
  return (
    <>
      <div className={styles.cardItemContainer}>
        <img src={item.style.item_images[0]} alt="uniform-image" className={styles.leftImage} onClick={onItemClick}/>
        <div className={styles.middleText} onClick={onItemClick}>
          <div className={styles.upperInfo}>
            <Ellipsis content={item.style[Helper.toLangKey("name")]}/>
            <RiArrowDropDownLine color={"var(--app-block-title-text-color)"} />
          </div>
          <div className={styles.middleInfo}>
            {/* {item[`color${langShortForm}`] ? item[`color${langShortForm}`] + ';' : null} {item.size}{langShortForm === 'Tc' ? '碼' : ' Size'} */}
            { `${item.color}; ${item.size[Helper.toLangKey("name")]}` }
          </div>
          <div className={styles.bottomInfo}>
            <p>
              <span style={{ fontSize: "15px" }}>$</span>
              <span style={{ fontSize: "25px" }}>{Helper.formatNumberWithCommas(item.price * item.qty)}</span>
            </p>
          </div>
        </div>
        <div className={styles.rightIcons}>
          <div className={styles.qty}>{`x${item.qty}`}</div>
          {
            isEditMode ? 
            <img src={deleteBinIcon} alt="delete-icon" className={styles.deleteIcon} onClick={onDeleteItemClick}/>
            :
            null
          }
        </div>
      </div>
    </>
  )
}

export default memo(ShoppingBagCardItem)