import React, { useEffect } from 'react';
import { App } from 'antd';
import { Log } from '../../utils/Log';
import { Result, ResultPage } from 'antd-mobile';
import { useTranslation } from 'react-i18next';
import { Helper } from '../../utils/Helper';
import { Navigate, useNavigate } from 'react-router';
import { LocalStorage } from '../../utils/LocalStorage';

const TAG = "PaymentSuccess";

const PaymentSuccess = ({
  totalAmount,
  details
}) => {
  
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    LocalStorage.set("shoppingBag", JSON.stringify([]));
  }, [])

  return (
    <App>
      <ResultPage
        className={'paymentSuccess'}
        status={'success'}
        title={
          <div style={{ fontSize: 15 }}>
            { t("t_paymentSuccess") }
          </div>
        }
        description={
          <>
            <span style={{ fontSize: 22, color: '#ffffff', marginRight: 4 }}>
              HK$
            </span>
            <span style={{ fontSize: 48, color: '#ffffff' }}>{Helper.formatNumberWithCommas(totalAmount)}</span>
          </>
        }
        details={details}
        secondaryButtonText={t("t_buyMore")}
        primaryButtonText={t("t_reviewOrder")}
        onSecondaryButtonClick={() => { navigate("/main/uniform") }}
        onPrimaryButtonClick={() => { navigate("/main/order") }}
      >
        <Result
          status={"success"}
          title={t("t_paymentSuccessCardHeader")}
          description={t("t_paymentSuccessMsg")}
        />
      </ResultPage>
    </App>
  )
}

export default PaymentSuccess