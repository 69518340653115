export const ThemeMap = {
  primary: "--adm-color-primary",
  success: "--adm-color-success",
  warning: "--adm-color-warning",
  danger: "--adm-color-danger",
  border: "--adm-color-border",
  text: "--adm-color-text-secondary",
  textSecondary: "--app-text-secondary-color",
  input: "--app-input-background-color",
  secondaryBackground: "--app-secondary-background-color",
  background: "--app-background-color",
  navbar: "--adm-nav-bar-background-color",
  navbarText: "--adm-nav-bar-text-color",
  tabbar: "--adm-tab-bar-background-color",
  tabbarText: "--adm-tab-bar-text-color",
  tabbarTextSecondary: "--adm-tab-bar-text-secondary-color",
  menu: "--app-menu-background-color",
  menuText: "--app-menu-text-color",
  uniformSetBoxShadow: "--app-uniform-set-box-shadow",
  uniformSetBoxShadowHover: "--app-uniform-set-box-shadow-hover",
  maleTag: "--app-gender-male-color",
  maleSelectorText: "--app-gender-selector-male-text-color",
  maleSelectorBackground: "--app-gender-selector-male-background-color",
  femaleTag: "--app-gender-female-color",
  femaleSelectorText: "--app-gender-selector-female-text-color",
  femaleSelectorBackground: "--app-gender-selector-female-background-color",
}