import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, Modal, Toast, DotLoading } from "antd-mobile";
import Stack from "../Stack";
import { AuthService } from "../../../service/apis/AuthService";
import { pop } from "../../../redux/tickStackSlice";
import { useDispatch } from "react-redux";

const AccountUpdateFormStack = ({
  visible,
  field,
  title,
  formItem,
  value
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm()
  const requiredRules = (field) => [{ required: true, message: `${t("t_isRequired", { field })}` }]
  const pleaseEnterField = (field) => t("t_pleaseEnterField", { field })
  const changeField = (field) => t("t_changeField", { field })

  useEffect(() => {
    if (!visible) return
    form.setFieldsValue({
      [field]: value
    })
  }, [visible])

  const onFinish = async (value) => {
    Modal.show({
      bodyClassName: "loading",
      content: <DotLoading color='primary' style={{ '--size': '32px' }} />,
      closeOnMaskClick: true,
    })
    const data = {
      column: field,
      value: value[field].toString()
    }
    const res = await AuthService.updateUser(data)
    if (res.success) {
      Modal.clear()
      Toast.show({
        icon: 'success',
        content: t("t_updateSuccess"),
        position: 'center',
      })
      dispatch(pop())
      form.resetFields()
    } else {
      Modal.clear()
      Toast.show({
        icon: 'fail',
        content: t("t_updateFail"),
        position: 'center',
      })
    }
  }

  return (
    <Stack
      title={changeField(title)}
      visible={visible}
    >
      <div style={{ width: "100%" }}>
        <Form
          form={form}
          onFinish={onFinish}
          footer={
            <Button block type='submit' color='primary' size='large'>
              {t("t_update")}
            </Button>
          }
        >
          <Form.Item
            name={field}
            label={title}
            rules={requiredRules(title)}
          >
            {formItem ? formItem : <Input clearable placeholder={pleaseEnterField(title)} />}
          </Form.Item>
        </Form>
      </div>
    </Stack>
  );
};

export default AccountUpdateFormStack;