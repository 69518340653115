import CryptoJS from "crypto-js"

const key = "VTZizH89IiAjTdtZlBAA6y9cxYo6PWnYXLApjiddtPNlVfc0LojlR51MiBYqoBOw"

export const encrypt = (value) => {
  return CryptoJS.AES.encrypt(value, key).toString();
}

export const decrypt = (value) => {
  return CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8);
}