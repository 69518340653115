import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, List, Card, Toast, Form, Input, Selector } from "antd-mobile";
import Stack from "../Stack";
import styles from "./CheckoutStack.module.scss";
import DeliveryMethodStack from "../deliveryMethod/DeliveryMethodStack";
import PaymentMethodStack from "../paymentMethod/PaymentMethodStack";
import { EnvironmentOutline } from 'antd-mobile-icons';
import CheckoutItem from "../../checkoutItem/CheckoutItem";
import TotalSumBottomBar from "../../totalSumBottomBar/TotalSumBottomBar";
import { useDispatch, useSelector } from "react-redux";
import { Helper } from "../../../utils/Helper";
import Bag from "../../../utils/entity/Bag";
import { Log } from "../../../utils/Log";
import { transactionFee } from "../../../configs/setting";
import { LocalStorage } from "../../../utils/LocalStorage";
import { DeliveryMethod } from "../../../data/deliveryMethod"
import PaymentMethodOption from "../../paymentMethodOption/PaymentMethodOption";
import { TransactionService } from "../../../service/apis/TransactionService";
import { endpoint } from "../../../configs/env";
import Response from "../../../utils/Response";
import { MasterDataService } from "../../../service/apis/MasterDataService";
import Item from "../../../utils/entity/Item";
import { push } from "../../../redux/tickStackSlice";
import AccountUpdateFormStack from "../accountUpdateFormStack/AccountUpdateFormStack";
import { RiArrowRightSLine } from "@remixicon/react";
import { AuthService } from "../../../service/apis/AuthService";

const TAG = "CheckoutStack";

const CheckoutStack = ({
  itemSets,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stackStatus = useSelector((state) => state.tickStack.status);
  const [form] = Form.useForm()
  // const [deliveryMethodStackVisible, setDeliveryMethodStackVisible] = useState(false)
  // const [paymentMethodStackVisible, setPaymentMethodStackVisible] = useState(false)
  const [deliveryAddress, setDeliveryAddress] = useState(undefined);
  const [pickupPoint, setPickupPoint] = useState(undefined);
  const [sfLockerCode, setSfLockerCode] = useState(undefined);
  const [deliveryMethod, setDeliveryMethod] = useState()
  const [paymentGateway, setPaymentGateway] = useState()
  const [paymentReminderModalVisible, setPaymentReminderModalVisible] = useState(false)
  const [checkoutItems, setCheckoutItems] = useState([])
  const [soldOutReminderModalVisible, setSoldOutReminderModalVisible] = useState(false)
  const genderOptions = Helper.getGenderOptions();
  const [bag, setBag] = useState(null);
  const nameLang = Helper.toLangKey("name");
  const [studentInfo, setStudentInfo] = useState({})
  const pickupPoints = LocalStorage.get("school").toObject().pickup_points;

  useEffect(() => {
    if (itemSets?.length <= 0) return setBag(null);
    setBag(new Bag(itemSets));
    // Log(TAG, new Bag(itemSets).get("SKTSJBR001"))
  }, [itemSets])

  useEffect(() => {
    if (!bag) return;
    mapMasterDataToItems();
  }, [bag])

  useEffect(() => {
    if (!deliveryAddress && !pickupPoint && !sfLockerCode) {
      setDeliveryMethod("")
    }
  }, [deliveryAddress, pickupPoint, sfLockerCode])

  useEffect(() => {
    getUser()
  }, [stackStatus.updateStudentName, stackStatus.updateStudentGender, stackStatus.updateStudentClassNo])

  const getUser = async () => {
    const user = await AuthService.user()
    if (user.success) {
      const student = user.data.student;
      if (!student) {
        getLocalStudent()
        return;
      }
      // const grade = Helper.getGradingCategoryByGradeKey(student["student_grade"].toUpperCase())
      const gender = genderOptions.find((item) => item.value === student["student_gender"])
      setStudentInfo({
        studentName: student["student_name"],
        studentGender: student["student_gender"].split(","),
        // studentClass: grade[nameLang],
        studentClassNo: student["student_grade"]
      })
      form.setFieldsValue({
        studentName: student["student_name"],
        studentGender: gender.label,
        // studentClass: grade[nameLang],
        studentClassNo: student["student_grade"]
      })
    }
  }

  const getLocalStudent = () => {
    const student = LocalStorage.get("student").value;
    if (!student) { alert("No student"); return; }
    Log(TAG, student)
    setStudentInfo({
      studentName: student["studentName"],
      studentGender: student["studentGender"][0],
      // studentClass: grade[nameLang],
      studentClassNo: student["studentClassNo"],
    })
    form.setFieldsValue({
      studentName: student["studentName"],
      studentGender: student["studentGender"][0],
      // studentClass: grade[nameLang],
      studentClassNo: student["studentClassNo"],
    })
  }

  const totalPrice = () => {
    if (!bag) return 0;
    return bag.total().price
  }

  const mapMasterDataToItems = async () => {
    const masterDataRes = await MasterDataService.get();
    let masterData;
    if (masterDataRes.success) masterData = masterDataRes.data;
    const callback = (value) => {
      const item = new Item(value);
      if (masterData["color_code"][item.color]) {
        value.color = masterData["color_code"][item.color]?.[Helper.langShortForm()]
      }
    }
    // Log(TAG, bag.toArray(callback))
    setCheckoutItems(bag.toArray(callback));
  }

  const onPaymentClick = () => {
    if (!deliveryMethod) {
      Toast.show({
        content: t("t_pleaseSelectDeliveryMethod"),
      })
      dispatch(push("deliveryMethod"));
      return
    }
    if (!paymentGateway) {
      Toast.show({
        content: t("t_pleaseSelectPaymentMethod"),
      })
      dispatch(push("paymentMethod"))
      return
    }
    setPaymentReminderModalVisible(true)
  }

  const onPaymentReminderConfirmClick = async () => {
    const bag = new Bag(LocalStorage.get("shoppingBag").toObject());

    const items = bag.toMap();
    const body = {
      // TO ASK: total amount include transaction fee ?
      total_amount: bag.total().price,
      handling_amount: transactionFee,
      payment_gateway: paymentGateway,
      delivery_method: deliveryMethod, //sf_cod,sf_locker,pickup_point
      delivery_address: deliveryAddress,
      pickup_point_id: pickupPoint,
      sf_locker_code: sfLockerCode,
      return_url: `${endpoint.baseUrl}/payment/`,
      items
    }
    Log(TAG, body)
    const res = await TransactionService.checkout(body);
    new Response(res).handle((data) => {
      window.location.href = data.url
    }, (data) => {
      if (data["X-User-Token"]) {
        Modal.alert({
          content: t("t_userNotLoggedIn"),
        })
      }
    })
    // if (res.success) {
    //   navigate("/payment/:code")
    // } else {
    // }
  }

  return (
    <>
      <Stack
        title={t("t_checkout")}
        visible={stackStatus.checkout}
      >
        <div className={styles.container}>
          <div className={styles.method}>
            <List mode='card'>
              <List.Item
                extra={
                  deliveryMethod === DeliveryMethod.PickupPoint ? t("t_storePickup") :
                    deliveryMethod === DeliveryMethod.SFCod ? t("t_SFToPay") :
                      deliveryMethod === DeliveryMethod.SFLocker ? t("t_SFLocker") :
                        t("t_pleaseSelect")
                }
                onClick={() => {
                  dispatch(push("deliveryMethod"));
                }}
              >
                {t("t_deliveryMethod")}
              </List.Item>
              {
                deliveryMethod === DeliveryMethod.PickupPoint ?
                  <List.Item>
                    <EnvironmentOutline /> {pickupPoint.address}
                  </List.Item>
                  : deliveryMethod === DeliveryMethod.SFCod ?
                    <List.Item>
                      <EnvironmentOutline /> {deliveryAddress}
                    </List.Item>
                    : deliveryMethod === DeliveryMethod.SFLocker ?
                      <List.Item>
                        {sfLockerCode}
                      </List.Item>
                      : null
              }
            </List>
          </div>
          <div className={styles.method}>
            <List mode="card">
              <List.Item
                extra={
                  paymentGateway === "paypal" ? t("t_creditCard") :
                    paymentGateway === "recon" ? t("t_electronicPayment") :
                      t("t_pleaseSelect")
                }
                onClick={() => {
                  dispatch(push("paymentMethod"))
                }}
              >
                {t("t_paymentMethod")}
              </List.Item>
              {paymentGateway &&
                <List.Item>
                  <PaymentMethodOption gateway={paymentGateway} />
                </List.Item>
              }
            </List>
          </div>
          <div className={styles.card}>
            <Card title={t("t_itemDetails")} className="listCardHeader" >
              {
                checkoutItems.length > 0 && checkoutItems.map((item, index) => {
                  return (
                    <CheckoutItem
                      key={index}
                      data={item}
                    />
                  )
                })
              }

            </Card>
          </div >
          <div className={styles.card}>
            <Card title={t("t_paymentDetails")} className="listCardHeader" >
              <div className={styles.paymentDetail}>
                <div >{t("t_totalAmount")}</div>
                <div>
                  <span className={styles.dollarSign}>HKD </span>
                  <span className={styles.amount}>{Helper.formatNumberWithCommas(totalPrice())}</span>
                </div>
              </div>
              <div className={styles.paymentDetail}>
                <div>{t("t_paymentFee")}</div>
                <div>
                  <span className={styles.dollarSign}>HKD </span>
                  <span className={styles.amount}>{Helper.formatNumberWithCommas(transactionFee)}</span>
                </div>
              </div>
            </Card>
          </div>
          <div className={styles.card}>
            <Card title={t("t_studentInfo")} className="listCardHeader">
              <Form
                layout='horizontal'
                className="checkoutStudentInfo"
                form={form}
              >
                <Form.Item
                  name='studentName'
                  label={t("t_studentName")}
                  onClick={() => {
                    dispatch(push("updateStudentName"))
                  }}
                >
                  <Input readOnly placeholder={t("t_pleaseEnter")} />
                </Form.Item>
                <Form.Item
                  name='studentGender'
                  label={t("t_studentGender")}
                  onClick={() => {
                    dispatch(push("updateStudentGender"))
                  }}
                >
                  <Input readOnly placeholder={t("t_pleaseEnter")} />
                </Form.Item>
                <Form.Item
                  name='studentClassNo'
                  label={t("t_studentClassNo")}
                  onClick={() => {
                    dispatch(push("updateStudentClassNo"))
                  }}
                >
                  <Input readOnly placeholder={t("t_pleaseEnter")} />
                </Form.Item>
              </Form>
            </Card>
          </div>
        </div>
        <div className={styles.lowerContainer}>
          <TotalSumBottomBar itemSets={itemSets} isCheckoutMode onClick={onPaymentClick} />
        </div>
        <DeliveryMethodStack
          setDeliveryMethod={setDeliveryMethod}
          setDeliveryAddress={setDeliveryAddress}
          setPickupPoint={setPickupPoint}
          setSfLockerCode={setSfLockerCode}
        />
        <PaymentMethodStack
          setPaymentGateway={setPaymentGateway}
        />
      </Stack>
      <Modal
        visible={paymentReminderModalVisible}
        content={t("t_termsOfUseText")}
        closeOnAction
        onClose={() => {
          setPaymentReminderModalVisible(false)
        }}
        actions={[
          {
            key: 'confirm',
            text: t("t_paymentReminderConfirm"),
            onClick: onPaymentReminderConfirmClick
          },
          {
            key: "cancel",
            text: t("t_cancel"),
            style: {
              color: "#000"
            }
          },
        ]}
      />
      <Modal
        visible={soldOutReminderModalVisible}
        content={
          <div className={styles.soldOutReminderModal}>
            <div className={styles.modalHeader}>{t("t_soldOutReminder")}</div>
            <div className={styles.modalContent}>{"夏季男生 36碼 x1"}</div>
          </div>
        }
        closeOnAction
        onClose={() => {
          setSoldOutReminderModalVisible(false)
        }}
        actions={[
          {
            key: 'confirm',
            text: t("t_paymentReminderConfirm"),
            onClick: () => {

            }
          },
        ]}
      />
      <AccountUpdateFormStack
        visible={stackStatus.updateStudentName}
        field={"student_name"}
        title={t("t_studentName")}
        value={studentInfo.studentName}
      />
      <AccountUpdateFormStack
        visible={stackStatus.updateStudentGender}
        formItem={
          <Selector
            className="genderSelector"
            columns={genderOptions.length}
            options={genderOptions}
          />
        }
        field={"student_gender"}
        title={t("t_studentGender")}
        value={studentInfo.studentGender}
      />
      <AccountUpdateFormStack
        visible={stackStatus.updateStudentClassNo}
        field={"student_class_no"}
        title={t("t_studentClassNo")}
        value={studentInfo.studentClassNo}
      />
    </>
  );
};

export default CheckoutStack;