import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Route, Routes, useParams } from 'react-router';
import { ProtectedRoute } from "./ProtectedRoute";
import Main from "../pages/main/Main";
import { useDispatch, useSelector } from "react-redux";
import UnitTest from "../pages/unitTest/UnitTest";
import TickSuccessModal from "../components/modals/TickSuccessModal";
import TickLoadingModal from "../components/modals/TickLoadingModal";
import TickWarningModal from "../components/modals/TickWarningModal";
import AuthContent from "../pages/authContent/AuthContent";
import "../themes/globalClass.scss";
import Landing from "../pages/Landing/Landing";
import Entry from "../pages/entry/Entry";
import CustomerService from "../pages/customerService/CustomerService";
import AboutSystem from "../pages/aboutSystem/AboutSystem";
import MyAccount from "../pages/myAccount/MyAccount";
import AccessDenied from "../pages/accessDenied/AccessDenied";
import { Logger } from "../utils/Log";
import PaymentFailure from "../pages/paymentFailure/PaymentFailure";
import PaymentSuccess from "../pages/paymentSuccess/PaymentSuccess";
import { LocalStorage } from "../utils/LocalStorage";
import { Payment } from "../pages/payment/Payment";
import LoginStack from "../components/stack/login/LoginStack";
import RegisterStack from "../components/stack/register/RegisterStack";
import PageNotFound from "../pages/pageNotFound/PageNotFound";

const AppRoute = () => {
  const tickGlobalModalState = useSelector((state) => state.tickModal);
  const loading = useSelector((state) => state.tickStack.status.loading);
  const stackStatus = useSelector((state) => state.tickStack.status);

  useEffect(() => {
    Logger.disable("TickPageSlice");
    Logger.disable("UniformFilterStack");
    Logger.disable("Uniform");
    Logger.disable("ShoppingBagCardItem");
    Logger.disable("CheckoutItem");

    if (LocalStorage.get("shoppingBag").isNull()) LocalStorage.set("shoppingBag", JSON.stringify([]))
  }, [])

  return (
    <>
      {
        loading ? (
          <Routes>
            <Route path="/*" element={<AccessDenied />} />
            <Route path="/entry" element={<Landing />} />
            <Route path="/main/*" element={<Landing />} />
            <Route path="/customerService" element={<Landing />} />
            <Route path="/aboutSystem" element={<Landing />} />
            <Route path="/account" element={<Landing />} />
            <Route path="/code/:schoolCode" element={<Landing />} />
            <Route 
              path="/accessDenied/:schoolCode?"
              element={
                <AccessDenied />
              }
            />
            <Route 
              path="/payment/:code" 
              element={
                <ProtectedRoute>
                  <Payment />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/pageNotFound/:schoolCode?"
              element={
                <PageNotFound />
              }
            />
          </Routes>
          // <Landing />
        ) : (
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route // Default root page
              path="/main/*"
              element={
                <ProtectedRoute>
                  <Main />
                </ProtectedRoute>
              }
            />
            <Route // need authorization
              path="/needAuthContent"
              element={
                <ProtectedRoute>
                  <AuthContent />,
                </ProtectedRoute>
              }
            />
            <Route 
              path="/unitTest" 
              element={
                <ProtectedRoute>
                  <UnitTest />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/entry" 
              element={
                <ProtectedRoute>
                  <Entry />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/customerService" 
              element={
                <ProtectedRoute>
                  <CustomerService />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/aboutSystem" 
              element={
                <ProtectedRoute>
                  <AboutSystem />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/account" 
              element={
                <ProtectedRoute>
                  <MyAccount/>
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/payment/:code" 
              element={
                <ProtectedRoute>
                  <Payment />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/paymentSuccess/:code" 
              element={
                <ProtectedRoute>
                  <PaymentSuccess/>
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/paymentFailure/:code" 
              element={
                <ProtectedRoute>
                  <PaymentFailure/>
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/accessDenied/:schoolCode?"
              element={
                <AccessDenied />
              }
            />
            <Route 
              path="/pageNotFound/:schoolCode?"
              element={
                <PageNotFound />
              }
            />
            {/* <Route
              // Redirect to root page when path is not found, root page can be main or home page
              // This must place at the bottom
              path="*"
              element={<Navigate to="/main/uniform" replace />}
            /> */}
          </Routes>
        )
      }
      
      {
        (!LocalStorage.get("school").isNull() && !LocalStorage.get("student").isNull()) && (
          <>
            <LoginStack />
            <RegisterStack name={"register"} visible={stackStatus.register} />
          </>
        )
      }

      {tickGlobalModalState.show &&
      tickGlobalModalState?.params?.key === "success" ? (
        <TickSuccessModal />
        ) : null}
      {tickGlobalModalState.show &&
      tickGlobalModalState?.params?.key === "loading" ? (
        <TickLoadingModal />
        ) : null}
      {tickGlobalModalState.show &&
      tickGlobalModalState?.params?.key === "warning" ? (
        <TickWarningModal />
        ) : null}
    </>
  );
};

{/* <Routes>
  <Route // Default root page
    path="/main/*"
    element={<Main />}
  />
  <Route // need authorization
    path="/needAuthContent"
    element={
      <ProtectedRoute>
        <AuthContent />,
      </ProtectedRoute>
    }
  />
  <Route path="/login" element={<Login />} />
  <Route path="/unitTest" element={<UnitTest />} />
  <Route
    // Redirect to root page when path is not found, root page can be main or home page
    // This must place at the bottom
    path="*"
    element={<Navigate to="/main" replace />}
  />
</Routes> */}

export default AppRoute;
