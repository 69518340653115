import React, { useEffect, useState } from "react";
import styles from "./LoginStack.module.scss";
import { DotLoading, Modal } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Form, Input } from "antd-mobile";
import Stack from "../Stack";
import Logo from "../../../assets/images/logo.png"
import { useDispatch, useSelector } from "react-redux";
import { pop, push, setStackVisible } from "../../../redux/tickStackSlice";
import ForgotPasswordStack from "../forgotPassword/ForgotPasswordStack";
import { Log } from "../../../utils/Log";
import { AuthService } from "../../../service/apis/AuthService";
import { LocalStorage } from "../../../utils/LocalStorage";
import Response from "../../../utils/Response";
import { EyeInvisibleOutline, EyeOutline } from 'antd-mobile-icons'
import RegisterStack from "../register/RegisterStack";
const TAG = "LoginStack";

const LoginStack = () => {

  const stackStatus = useSelector((state) => state.tickStack.status);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm()
  const [passwordVisible, setPasswordVisible] = useState(false)

  const registerBtn = (
    <div>
      <Button
        style={{ padding: 0, margin: "5px 0 0 0", fontSize: 16 }}
        fill='none'
        color='primary'
        onClick={() => {
          // dispatch(setStackVisible({ key: "register", value: true }))
          dispatch(push("loginRegister"))
        }}
      >
        {t("t_register")}
      </Button>
    </div>
  )

  const onForgotPassword = () => {
    dispatch(push("forgotPassword"))
  }

  const onFinish = (value) => {
  }

  const onLoginClick = async () => {
    Log(TAG, form.getFieldsValue())
    Modal.show({
      bodyClassName: "loading",
      content: <DotLoading color='primary' style={{ '--size': '32px' }} />,
      closeOnMaskClick: true,
    })

    const res = await AuthService.login(form.getFieldsValue())
    new Response(res).handle((data) => {
      // Success callback
      LocalStorage.set("token", data.token)
      setTimeout(() => {
        Modal.clear();
        window.location.reload()
        // dispatch(setStackVisible({ key: "login", value: false }))
      }, 1500)
    })
    // window.location.reload();
  }

  return (
    <>
      <Stack
        title={t("t_login")}
        visible={stackStatus.login}
        setVisible={(status) => {
          dispatch(setStackVisible({ key: "login", value: status }))
        }}
        right={registerBtn}
        position={"bottom"}
      >
        <div className={styles.loginContainer}>
          <div className={styles.logo}>
            <img src={Logo} style={{ width: "200px" }} />
          </div>
          <div>
            <Form
              form={form}
              onFinish={onFinish}
              footer={
                <>
                  <Button block type='submit' color='primary' size='large' onClick={onLoginClick}>
                    {t('t_login')}
                  </Button>
                  <br />
                  <Button block fill='none' color='primary' size="large" onClick={onForgotPassword}>
                    {t("t_forgotPassword")}
                  </Button>
                </>
              }
            >
              <Form.Item
                name='email'
                label={t("t_emailAddress")}
              >
                <Input id="login_emailAddress" clearable placeholder={`${t("t_pleaseEnter")}${t("t_emailAddress")}`}  />
              </Form.Item>
              <Form.Item
                name='password'
                label={t("t_password")}
                extra={
                  <div >
                    {!passwordVisible ? (
                      <EyeInvisibleOutline onClick={() => setPasswordVisible(true)} />
                    ) : (
                      <EyeOutline onClick={() => setPasswordVisible(false)} />
                    )}
                  </div>
                }
              >
                <Input 
                  id="login_password" 
                  type={passwordVisible ? 'text' : 'password'} 
                  placeholder={`${t("t_pleaseEnter")}${t("t_password")}`} 
                  autoComplete="on"
                />
              </Form.Item>
            </Form>
          </div>
        </div>
        <RegisterStack name={"loginRegister"} visible={stackStatus.loginRegister} />
        <ForgotPasswordStack />
      </Stack>
    </>
  );
};

export default LoginStack;
