import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../redux/tickModalSlice";
import { Modal } from "antd-mobile";
import styles from "./modal.module.scss";

const TickGlobalModal = (props) => {
  const { children, autoClose = 0 } = props;
  const dispatch = useDispatch();
  const tickGlobalModalState = useSelector((state) => state.tickModal);

  useEffect(() => {
    if (tickGlobalModalState.show) {
      if (autoClose > 0) {
        setTimeout(() => {
          dispatch(closeModal());
        }, autoClose);
      } else if (tickGlobalModalState.params.autoClose > 0) {
        setTimeout(() => {
          dispatch(closeModal());
        }, tickGlobalModalState.params.autoClose);
      }
    }
  }, []);

  return (
    <Modal
      // wrapClassName={styles.tickGlobalModalContainer}
      visible={tickGlobalModalState.show}
      // maskClosable={false}
      // centered
      // footer={null}
      // closeIcon={null}
    >
      {children}
    </Modal>
  );
};

export default TickGlobalModal;
