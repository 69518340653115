import request from "../request";

export class AuthService {
  
  static login = async (body) => {
    return await request.post(`/login`, { ...body });
  };

  static registration = async (body) => {
    return await request.post(`/registration`, { ...body });
  }

  static user = async () => {
    return await request.get('/user');
  }

  static updateUser = async (body) => {
    return await request.post('/user', { ...body })
  }

}



// Above example just for test axios 
// If you need to modify just override it