import request from "../request";

export class ThemeService {

  static prefix = `/color`;
  
  static get = async () => {
    return await request.get(`${this.prefix}`);
  };

  static getDarkTheme = async () => {
    return await request.get(`/dark-theme`);
  };

}