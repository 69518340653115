import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, Selector, Modal, DotLoading, Toast } from "antd-mobile";
import Stack from "../Stack";
import { useDispatch, useSelector } from "react-redux";
import { pop, push, setStackVisible } from "../../../redux/tickStackSlice";
import StudentClassCheckListStack from "../studentClassCheckList/StudentClassCheckListStack"
import { Helper } from "../../../utils/Helper";
import { EyeInvisibleOutline, EyeOutline } from 'antd-mobile-icons'
import { AuthService } from "../../../service/apis/AuthService";
import { LocalStorage } from "../../../utils/LocalStorage";
import { Log } from "../../../utils/Log";

const RegisterStack = ({ name, visible }) => {
  const stackStatus = useSelector((state) => state.tickStack.status);
  const pageState = useSelector((state) => state.tickPage.params);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm()
  const [studentClass, setStudentClass] = useState();
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
  const genderOptions = Helper.getGenderOptions();
  const langShortForm = Helper.langShortForm();
  const student = LocalStorage.get("student").value
  const requiredRules = (field) => [{ required: true, message: `${t("t_isRequired", { field })}` }]
  const pleaseSelectField = (field) => t("t_pleaseSelectField", { field })
  const pleaseEnterField = (field) => t("t_pleaseEnterField", { field })
  const isRequired = (field) => t("t_isRequired", { field })

  useEffect(() => {
    setStudentClass(student.studentClass)
    form.setFieldsValue({
      name: student.name,
      studentClassNo: student.studentClassNo,
      studentGender: student.studentGender,
      studentName: student.studentName,
    })
  }, [stackStatus.register])

  useEffect(() => {
    if (!studentClass) return;
    form.setFieldsValue({
      studentClass,
    })
  }, [studentClass])

  const onFinish = async (value) => {
    Modal.show({
      bodyClassName: "loading",
      content: <DotLoading color='primary' style={{ '--size': '32px' }} />,
      closeOnMaskClick: true,
    })

    const data = {
      name: value.name,
      mobile: value.mobilePhone,
      email: value.emailAddress,
      password: value.password,
      student_name: value.studentName,
      student_gender: value.studentGender ? value.studentGender.join(",") : undefined,
      // student_grade: studentClass ? studentClass.code : undefined
      student_grade: value.studentClassNo
    }
    const res = await AuthService.registration(data)
    if (res.success) {
      Modal.clear()
      Toast.show({
        icon: 'success',
        content: t("t_registeredSuccessfully"),
        position: 'center',
      })
      setTimeout(() => {
        if (stackStatus.register || stackStatus.loginRegister) dispatch(pop())
        form.resetFields()
        dispatch(push("login"))
      }, 1500);
    } else {
      Modal.clear()
      Toast.show({
        icon: 'fail',
        content: res.message,
        position: 'center',
      })
    }
  }

  const confirmPasswordChecking = () => {
    const { password, confirmPassword } = form.getFieldsValue()
    if (!confirmPassword) {
      return Promise.resolve()
    }
    if (password === confirmPassword) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(t("t_confirmPasswordChecking")))
  }

  return (
    <Stack
      title={t("t_register")}
      visible={visible}
      setVisible={(status) => {
        dispatch(setStackVisible({ key: "register", value: status }))
      }}
      onBack={() => { form.resetFields() }}
    >
      <div style={{ width: "100%" }}>
        <Form
          form={form}
          onFinish={onFinish}
          footer={
            <Button block type='submit' color='primary' size='large' >
              {t("t_confirm")}
            </Button>
          }
        >
          <Form.Header>{t("t_userProfile")}</Form.Header>
          <Form.Item
            name='name'
            label={t("t_name")}
            rules={requiredRules(t("t_name"))}
          >
            <Input id={`${name}_name`} placeholder={pleaseEnterField(t("t_name"))} />
          </Form.Item>
          <Form.Item
            name='emailAddress'
            label={t("t_emailAddress")}
            rules={requiredRules(t("t_emailAddress"))}
          >
            <Input id={`${name}_emailAddress`} placeholder={pleaseEnterField(t("t_emailAddress"))} />
          </Form.Item>
          <Form.Item
            name='mobilePhone'
            label={t("t_mobilePhone")}
            rules={requiredRules(t("t_mobilePhone"))}
          >
            <Input id={`${name}_mobilePhone`} placeholder={pleaseEnterField(t("t_mobilePhone"))} />
          </Form.Item>
          <Form.Header>{`${t("t_password")}（${t("t_passwordRule")}）`}</Form.Header>
          <Form.Item
            name='password'
            label={t("t_password")}
            rules={[
              {
                required: true,
                message: isRequired(t("t_password"))
              },
              {
                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,12}$/,
                message: t("t_passwordRule")
              },
            ]}
            extra={
              <div >
                {!passwordVisible ? (
                  <EyeInvisibleOutline onClick={() => setPasswordVisible(true)} />
                ) : (
                  <EyeOutline onClick={() => setPasswordVisible(false)} />
                )}
              </div>
            }
          >
            <Input 
              id={`${name}_password`} 
              type={passwordVisible ? 'text' : 'password'} 
              placeholder={pleaseEnterField(t("t_password"))} 
              autoComplete="on"
            />
          </Form.Item>
          <Form.Item
            name='confirmPassword'
            label={t("t_confirmPassword")}
            rules={[
              {
                required: true,
                message: isRequired(t("t_confirmPassword"))
              },
              {
                validator: confirmPasswordChecking,
              }
            ]}
            extra={
              <div >
                {!confirmPasswordVisible ? (
                  <EyeInvisibleOutline onClick={() => setConfirmPasswordVisible(true)} />
                ) : (
                  <EyeOutline onClick={() => setConfirmPasswordVisible(false)} />
                )}
              </div>
            }
          >
            <Input 
              id={`${name}_confirmPassword`} 
              type={confirmPasswordVisible ? 'text' : 'password'} 
              placeholder={pleaseEnterField(t("t_confirmPassword"))} 
              autoComplete="on"
            />
          </Form.Item>
          <Form.Header>{t("t_studentProfile")} - {LocalStorage.get("school").toObject()[Helper.toLangKey("name")]}</Form.Header>
          <Form.Item
            name='studentName'
            label={t("t_studentName")}
            rules={requiredRules(t("t_studentName"))}
          >
            <Input id={`${name}_studentName`} placeholder={pleaseEnterField(t("t_studentName"))} />
          </Form.Item>
          <Form.Item
            name='studentGender'
            label={t("t_studentGender")}
            rules={requiredRules(t("t_studentGender"))}
          >
            <Selector
              className="genderSelector"
              columns={genderOptions.length}
              options={genderOptions}
            />
          </Form.Item>
          {/* <Form.Item
            name={['studentClass', `${Helper.toLangKey("name")}`]}
            label={t("t_studentClass")}
            trigger='onConfirm'
            onClick={() => {
              dispatch(push("studentClassCheckList"))
            }}
            rules={[
              {
                required: true,
                message: `${t("t_pleaseSelect")}${t("t_studentClass")}`
              },
            ]}
          >
            <Input id={`${name}_studentClass`} placeholder={pleaseSelectField(t("t_studentClass"))} />
          </Form.Item> */}
          <Form.Item
            name='studentClassNo'
            label={t("t_studentClassNo")}
          >
            <Input id={`${name}_studentClassNo`} placeholder={pleaseEnterField(t("t_studentClassNo"))} />
          </Form.Item>
        </Form>
      </div>
      {/* <StudentClassCheckListStack
        title={pleaseSelectField(t("t_studentClass"))}
        setStudentClass={setStudentClass}
      /> */}
    </Stack>
  );
};

export default RegisterStack;
