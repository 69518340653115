import { useTranslation } from "react-i18next";
import { Button, Form, Input, Selector, Swiper } from "antd-mobile";
import { useEffect, useState } from "react";
import { App } from "antd"
import School from "../../assets/images/school.png";
import { useDispatch, useSelector } from "react-redux";
import StudentClassCheckListStack from "../../components/stack/studentClassCheckList/StudentClassCheckListStack";
import { reset, setEntryPageParam } from "../../redux/tickPageSlice";
import { useNavigate } from "react-router-dom";
import { Helper } from "../../utils/Helper";
import { LocalStorage } from "../../utils/LocalStorage";
import { AuthService } from "../../service/apis/AuthService";
import Response from "../../utils/Response";
import { push } from "../../redux/tickStackSlice";
import { Log } from "../../utils/Log";

const TAG = "Entry";

const Entry = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const stackStatus = useSelector((state) => state.tickStack.status);

  const [form] = Form.useForm()
  const dispatch = useDispatch();

  const [studentClass, setStudentClass] = useState();
  const [user, setUser] = useState();

  const genderOptions = Helper.getGenderOptions();
  const student = LocalStorage.get("student").value
  const school = LocalStorage.get("school").toObject()
  const images = school.images

  const fetch = async () => {
    if (!LocalStorage.get("token").isNull()) {
      const res = await AuthService.user();
      new Response(res).handle((data) => { setUser(data) })
    }
  }

  const getLocalStudent = () => {
    if (!student) return
    Log(TAG, student)
    if (student.schoolCode !== school.code) {
      LocalStorage.remove("student");
    } else {
      delete student.schoolCode;
      setStudentClass(student.studentClass)
      form.setFieldsValue(student)
    }
  }

  const updateStudent = async () => {
    await AuthService.updateUser({ column: "student_name", value: form.getFieldValue("studentName") });
    await AuthService.updateUser({ column: "student_grade", value: form.getFieldValue("studentClassNo") });
    await AuthService.updateUser({ column: "student_gender", value: form.getFieldValue("studentGender")[0] });
  }

  useEffect(() => {
    dispatch(reset());
    fetch();
    getLocalStudent();
    LocalStorage.set("popupState", 1)
  }, [])

  useEffect(() => {
    if (user) {
      form.setFieldsValue({ name: user.name })
    }
  }, [user])

  useEffect(() => {
    if (!studentClass) return;
    form.setFieldsValue({
      studentClass,
    })
  }, [studentClass])

  const onFinish = async (value) => {
    if (!LocalStorage.get("token").isNull()) {
      // If logged in
      await updateStudent();
    } else {
      value.studentClass = studentClass
      value.schoolCode = school.code;
      LocalStorage.set("student", value)
    }
    navigate("/main/uniform")
  }

  const requiredRules = (field) => [{ required: true, message: `${t("t_isRequired", { field })}` }]
  const pleaseSelectField = (field) => t("t_pleaseSelectField", { field })
  const pleaseEnterField = (field) => t("t_pleaseEnterField", { field })

  return (
    <>
      <App>
        <div style={{ width: "100%", height: "250px" }}>
            <Swiper autoplay loop autoplayInterval={5000}>
              {
                images.map((img, index) =>
                  <Swiper.Item key={index}>
                    <img src={img} alt="school-image" style={{ width: "100%", height: "250px" }} />
                  </Swiper.Item>
                )
              }
            </Swiper>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          footer={
            <Button block type='submit' color='primary' size='large'>
              {t("t_enterUniformPage", { schoolName: LocalStorage.get("school").toObject()[Helper.toLangKey("name")] })}
            </Button>
          }
        >
          <Form.Header>{t("t_userProfile")}</Form.Header>
          <Form.Item
            name='name'
            label={t("t_parentName")}
            disabled={user ? true : false}
            rules={requiredRules(t("t_name"))}
          >
            <Input placeholder={pleaseEnterField(t("t_name"))} />
          </Form.Item>
          <Form.Header>{t("t_studentProfile")} - {LocalStorage.get("school").toObject()[Helper.toLangKey("name")]}</Form.Header>
          <Form.Item
            name='studentName'
            label={t("t_studentName")}
            rules={requiredRules(t("t_studentName"))}
          >
            <Input placeholder={pleaseEnterField(t("t_studentName"))} />
          </Form.Item>
          <Form.Item
            name='studentGender'
            label={t("t_studentGender")}
            rules={requiredRules(t("t_studentGender"))}
            initialValue={genderOptions.length === 1 ? [genderOptions[0].value] : []}
          >
            <Selector
              className="genderSelector"
              columns={genderOptions.length}
              options={genderOptions} 
            />
          </Form.Item>
          {/* <Form.Item
            name={['studentClass', `${Helper.toLangKey("name")}`]}
            label={t("t_studentClass")}
            onClick={() => {
              dispatch(push("studentClassCheckList"))
            }}
            rules={requiredRules(t("t_studentClass"))}
          >
            <Input placeholder={pleaseSelectField(t("t_studentClass"))} />
          </Form.Item> */}
          <Form.Item
            name='studentClassNo'
            label={t("t_studentClassNo")}
          >
            <Input placeholder={pleaseEnterField(t("t_studentClassNo"))} />
          </Form.Item>
        </Form>
      </App>
      {/* <StudentClassCheckListStack
        title={`${t("t_pleaseSelect")}${t("t_studentClass")}`}
        setStudentClass={setStudentClass}
      /> */}
    </>
  )
}

export default Entry;