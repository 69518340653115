import React, { useEffect, useState } from 'react';
import styles from './TotalSumBottomBar.module.scss'
import { useTranslation } from "react-i18next";
import { Helper } from '../../utils/Helper';
import { Button } from 'antd-mobile';
import { useSelector } from 'react-redux';
import Bag from '../../utils/entity/Bag';
import { Log } from '../../utils/Log';
import { transactionFee } from '../../configs/setting';

const TAG = "TotalSumBottomBar";

const TotalSumBottomBar = ({ 
  itemSets,
  isCheckoutMode,
  onClick
}) => {
  const { t } = useTranslation();
  const [bag, setBag] = useState(null);

  const totalPrice = () => {
    if (!bag) return 0;
    const totalPrice = bag.total()?.price;
    return isCheckoutMode ? totalPrice + transactionFee : totalPrice;
  }

  useEffect(() => {
    if (itemSets?.length <= 0) return setBag(null);
    setBag(new Bag(itemSets));
  }, [itemSets])

  return (
    <>
      <div className={styles.price}>
        <p>
          {
            isCheckoutMode ? 
            <span className={styles.qty}>{ `${ bag ? `${bag?.total()?.qty}${t("t_pieces")}` : `0${t("t_piece")}` }`}</span>
            :
            null
          }
          <span className={styles.totalText}>{t("t_shoppingBagTotal") + ':'}</span>
          <span className={styles.dollarSign}>{isCheckoutMode ? 'HKD' : '$'}</span>
          <span className={styles.totalSum}>{ totalPrice() }</span>
        </p>
      </div>
      <Button size='middle' color='primary' style={{ width: "100px"}} onClick={onClick} disabled={bag === null}>
        {isCheckoutMode ? t("t_payment") : t("t_checkout")}
      </Button> 
    </>
  )
}

export default TotalSumBottomBar