import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Toast, List, TextArea, Modal, DotLoading, Space } from "antd-mobile";
import Stack from "../Stack";
import UpdateDeliveryAddressStack from "../updateDeliveryAddress/UpdateDeliveryAddressStack";
import { UserAddressService } from "../../../service/apis/UserAddressService";
import { DeleteOutline } from "antd-mobile-icons"
import styles from "./ManageDeliveryAddressStack.module.scss"
import { useDispatch, useSelector } from "react-redux";
import { push } from "../../../redux/tickStackSlice";

const ManageDeliveryAddressStack = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stackStatus = useSelector((state) => state.tickStack.status);

  const [form] = Form.useForm()
  const [userAddress, setUserAddress] = useState({})
  const [addressId, setAddressId] = useState();
  const [addressText, setAddressText] = useState();

  useEffect(() => {
    getUserAddress()
  }, [stackStatus.updateDeliveryAddress])

  const getUserAddress = async () => {
    const res = await UserAddressService.get()
    if (res.success) {
      setUserAddress(res.data)
    }
  }

  const onDeliveryAddressClick = (e) => {
    setAddressText(e.target.innerText)
    setAddressId(setAddressIdByValue(e.target.innerText))
    dispatch(push("updateDeliveryAddress"))
  }

  const setAddressIdByValue = (value) => {
    let address_id
    for (const key in userAddress) {
      if (userAddress[key] === value) {
        address_id = key
      }
    }
    return address_id
  }

  const onFinish = async (value) => {
    Modal.show({
      bodyClassName: "loading",
      content: <DotLoading color='primary' style={{ '--size': '32px' }} />,
      closeOnMaskClick: true,
    })
    const data = {
      address: value.deliveryAddress
    }
    const res = await UserAddressService.create(data)
    if (res.success) {
      Modal.clear()
      Toast.show({
        icon: 'success',
        content: t("t_addSuccess"),
        position: 'center',
      })
      form.resetFields()
      getUserAddress()
    } else {
      Modal.clear()
      Toast.show({
        icon: 'fail',
        content: t("t_addFail"),
        position: 'center',
      })
    }
  }

  const onDeleteAddressClick = (value) => {
    Modal.confirm({
      content: t("t_confirmDelete"),
      confirmText: t("t_yes"),
      cancelText: t("t_no"),
      onConfirm: () => onDeleteAddressConfirm(value),
      onCancel: () => {
        Modal.clear();
      }
    })
  }

  const onDeleteAddressConfirm = async (value) => {
    Modal.show({
      bodyClassName: "loading",
      content: <DotLoading color='primary' style={{ '--size': '32px' }} />,
      closeOnMaskClick: true,
    })
    const address_id = setAddressIdByValue(value)
    const res = await UserAddressService.delete(address_id)
    if (res.success) {
      Modal.clear()
      Toast.show({
        icon: 'success',
        content: t("t_deleteSuccess"),
        position: 'center',
      })
      getUserAddress()
    } else {
      Modal.clear()
      Toast.show({
        icon: 'fail',
        content: t("t_deleteFail"),
        position: 'center',
      })
    }
  }

  return (
    <>
      <Stack
        title={t("t_manageDeliveryAddress")}
        visible={stackStatus.manageDeliveryAddress}
      >
        <div style={{ width: "100%" }}>
          {
            Object.values(userAddress).length > 0 ?
              <List header={t('t_editDeliveryAddress')}>
                {
                  Object.values(userAddress).map((value, index) => {
                    return (
                      <div className={styles.listItemContainer} key={index}>
                        <Button className={styles.btn} onClick={() => onDeleteAddressClick(value)}>
                          <DeleteOutline color="var(--adm-color-danger)" />
                        </Button>
                        <div className={styles.listItem}>
                          <List.Item onClick={onDeliveryAddressClick}>
                            {value}
                          </List.Item>
                        </div>
                      </div>
                    )
                  })
                }
              </List>
              : null
          }
          <List header={t('t_addDeliveryAddress')}></List>
          <Form
            form={form}
            onFinish={onFinish}
            footer={
              <Button block type='submit' color='primary' size='large'>
                {t('t_addDeliveryAddress')}
              </Button>
            }
          >
            <Form.Item
              name={"deliveryAddress"}
              label={t("t_deliveryAddress")}
            >
              <TextArea
                id="manageDeliveryAddress"
                showCount
                maxLength={50}
                placeholder={`${t("t_pleaseEnter")}${t("t_deliveryAddress")}`}
              />
            </Form.Item>
          </Form>
        </div>
        <UpdateDeliveryAddressStack addressId={addressId} addressText={addressText} />
      </Stack>
    </>
  );
};

export default ManageDeliveryAddressStack;