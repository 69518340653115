import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, List, CheckList, Card } from "antd-mobile";
import Stack from "../Stack";
import ManageDeliveryAddressStack from "../manageDeliveryAddress/ManageDeliveryAddressStack";
import { LocalStorage } from "../../../utils/LocalStorage";
import { Helper } from "../../../utils/Helper";
import { UserAddressService } from "../../../service/apis/UserAddressService";
import { DeliveryMethod } from "../../../data/deliveryMethod";
import { useDispatch, useSelector } from "react-redux";
import { pop, push } from "../../../redux/tickStackSlice";

const DeliveryMethodStack = ({
  setDeliveryMethod,
  setDeliveryAddress,
  setPickupPoint,
  setSfLockerCode
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  const [form] = Form.useForm()
  const [userAddress, setUserAddress] = useState({})

  const stackStatus = useSelector((state) => state.tickStack.status);
  const stacks = useSelector((state) => state.tickStack.stacks);

  const langShortForm = Helper.langShortForm();
  const addresslang = Helper.toLangKey("address");
  const pickupPoints = LocalStorage.get("school").toObject().pickup_points;

  useEffect(() => {
    getUserAddress()
  }, [stackStatus.manageDeliveryAddress])

  const onManageDeliveryAddressClick = () => {
    dispatch(push("manageDeliveryAddress"))
  }

  const closeSelf = () => {
    if (Helper.peek(stacks) === "deliveryMethod") dispatch(pop());
  }

  const getUserAddress = async () => {
    const res = await UserAddressService.get()
    if (res.success) {
      setUserAddress(res.data)
    }
  }

  const onAddressChange = (val) => {
    const isPickup = pickupPoints.find(item => item.address_tc === val[0])
    if (isPickup) {
      setDeliveryAddress(undefined)
      setSfLockerCode(undefined)
      setDeliveryMethod(DeliveryMethod.PickupPoint)
      setPickupPoint({id: isPickup.id, address: isPickup[addresslang]})
    }else {
      setPickupPoint(undefined)
      setSfLockerCode(undefined)
      setDeliveryMethod(DeliveryMethod.SFCod)
      setDeliveryAddress(val[0])
    }
    form.resetFields()
    closeSelf()
  }

  const onSelectedLockerCode = () => {
    const lockerCode = form.getFieldValue("lockerCode")
    if (lockerCode) {
      setDeliveryAddress(undefined)
      setPickupPoint(undefined)
      setDeliveryMethod(DeliveryMethod.SFLocker)
      setSfLockerCode(lockerCode)
      closeSelf()
    }else {
      setDeliveryMethod("")
      setDeliveryAddress(undefined)
      setPickupPoint(undefined)
      setSfLockerCode(undefined)
      closeSelf()
    }
  }

  return (
    <>
      <Stack
        title={`${t("t_pleaseSelect")}${t("t_deliveryMethod")}`}
        visible={stackStatus.deliveryMethod}
      >
        <div style={{ width: "100%" }}>
          <CheckList
            onChange={onAddressChange}
          >
            <List header={t("t_storePickup")}>
              {
                pickupPoints.map((item, i) => {
                  return (
                    <CheckList.Item key={i} value={item.address_tc}>{item[addresslang]}</CheckList.Item>
                  )
                })
              }
            </List>
            <List header={t("t_SFToPay")}>
              {
                Object.values(userAddress).map((value, i) => {
                  return (
                    <CheckList.Item key={i} value={value}>
                      {value}
                    </CheckList.Item>
                  )
                })
              }
              <List.Item onClick={onManageDeliveryAddressClick}>
                {t("t_manageDeliveryAddress")}
              </List.Item>
            </List>
          </CheckList>
          <List header={t("t_SFLocker")}></List>
          <Form
            form={form}
            footer={
              <Button block type='submit' color='primary' size='large' onClick={onSelectedLockerCode}>
                {t('t_confirm')}
              </Button>
            }
          >
            <Form.Item
              name={"lockerCode"}
              label={t("t_SFLockerCode")}
              help={
                <>
                  <a
                    href={`https://htm.sf-express.com/HK/${langShortForm}/dynamic_function/S.F.Network/SF_Locker/`}
                    target="_blank"
                  >
                    SF Locker
                  </a>
                </>}
            >
              <Input placeholder={"H852XXXXX"} />
            </Form.Item>
          </Form>
        </div>
        <ManageDeliveryAddressStack />
      </Stack>
    </>
  );
};

export default DeliveryMethodStack;