import React from 'react';
import styles from './ArrowButton.module.scss'
import { RiArrowRightSLine } from '@remixicon/react';
import { Button } from 'antd-mobile';

const ArrowButton = ({ 
  title,
  textColor = "var(--adm-color-text-secondary)",
  lineColor = "var(--adm-color-border)",
  backgroundColor,
  onClick,
  fontSize,
  height
}) => {
  return (
    <Button 
      className={styles.arrowButton} 
      block 
      shape='rectangular' 
      onClick={onClick} 
      style={{ backgroundColor, borderBottom: `1px ${lineColor} solid` }}
    >
      <div className={styles.arrowButtonContent} style={{height}}>
        <p style={{ color: textColor, fontSize }}>{ title || "Arrow Button" }</p>
        <RiArrowRightSLine color={textColor} />
      </div>
    </Button>
  )
}

export default ArrowButton