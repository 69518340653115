import React, { useEffect, useState } from "react";
import { App } from "antd";
import { NavBar } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, Selector } from "antd-mobile";
import { useDispatch, useSelector } from "react-redux";
import Menu from "../../components/menu/Menu";
import UpdatePasswordStack from "../../components/stack/updatePassword/UpdatePasswordStack";
import { AuthService } from "../../service/apis/AuthService";
import { LocalStorage } from "../../utils/LocalStorage";
import { Helper } from "../../utils/Helper";
import AccountUpdateFormStack from "../../components/stack/accountUpdateFormStack/AccountUpdateFormStack";
import { push } from "../../redux/tickStackSlice";

const MyAccount = () => {
  const genderOptions = Helper.getGenderOptions();
  const stackState = useSelector((state) => state.tickStack);
  const { t } = useTranslation();
  const [form] = Form.useForm()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [menuVisible, setMenuVisible] = useState(false);
  const [studentInfo, setStudentInfo] = useState({})
  const changeField = (field) => t("t_changeField", { field })

  useEffect(() => {
    getUser()
  }, [
    stackState.status.updateName,
    stackState.status.updateMobilePhone,
    stackState.status.updateStudentName,
    stackState.status.updateStudentGender,
    stackState.status.updateStudentClass,
    stackState.status.updateStudentClassNo
  ])

  const getUser = async () => {
    const user = await AuthService.user()
    if (user.success) {
      const student = user.data.student;
      if (!student) {
        getLocalStudent()
        return;
      }
      // const grade = Helper.getGradingCategoryByGradeKey(student["student_grade"].toUpperCase())
      const gender = genderOptions.find((item) => item.value === student["student_gender"])
      setStudentInfo({
        name: user.data.name,
        mobilePhone: user.data.mobile,
        studentName: student["student_name"],
        studentGender: student["student_gender"]?.split(","),
        studentClassNo: student["student_grade"]
      })
      form.setFieldsValue({
        name: user.data.name,
        emailAddress: user.data.email,
        mobilePhone: user.data.mobile,
        studentName: student["student_name"],
        studentGender: gender?.label,
        // studentClass: {
        //   key: grade.code,
        //   category_id: grade["category_id"],
        //   name_tc: grade["name_tc"],
        //   name_en: grade["name_en"]
        // }
        studentClassNo: student["student_grade"]
      })
    }
  }

  const getLocalStudent = () => {
    const student = LocalStorage.get("student").value;
    if (!student) { alert("No student"); return; }
    setStudentInfo({
      studentName: student["studentName"],
      studentGender: student["studentGender"][0],
      // studentClass: grade[nameLang],
      studentClassNo: student["studentClassNo"],
    })
    form.setFieldsValue({
      studentName: student["studentName"],
      studentGender: student["studentGender"][0],
      // studentClass: grade[nameLang],
      studentClassNo: student["studentClassNo"],
    })
  }

  const onLogoutClick = () => {
    LocalStorage.remove("token");
    LocalStorage.remove("student");
    window.location.reload(); 
    navigate('/main/uniform');
  }

  return (
    <>
      <App>
        <NavBar className="nav" backArrow onBack={() => navigate("/main/uniform")} right={<Menu visible={menuVisible} setVisible={setMenuVisible} />}>
          {t("t_myAccount")}
        </NavBar>
        <div>
          <Form
            layout='horizontal'
            footer={
              <Button block type='submit' color='primary' size='large' onClick={onLogoutClick}>
                {t("t_logout")}
              </Button>
            }
            form={form}
          >
            <Form.Item
              name='name'
              label={t("t_name")}
              onClick={() => {
                dispatch(push("updateName"))
              }}
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name='emailAddress'
              label={t("t_emailAddress")}
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name='mobilePhone'
              label={t("t_mobilePhone")}
              onClick={() => {
                dispatch(push("updateMobilePhone"))
              }}
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name=''
              label={changeField(t("t_password"))}
              onClick={() => {
                dispatch(push("updatePassword"))
              }}
            >
            </Form.Item>
            <Form.Header>{t("t_studentProfile")} - {LocalStorage.get("school").toObject()[Helper.toLangKey("name")]}</Form.Header>
            <Form.Item
              name='studentName'
              label={t("t_studentName")}
              onClick={() => {
                dispatch(push("updateStudentName"))
              }}
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name='studentGender'
              label={t("t_studentGender")}
              onClick={() => {
                dispatch(push("updateStudentGender"))
              }}
            >
              <Input readOnly />
            </Form.Item>
            {/* <Form.Item
              name={['studentClass', `${Helper.toLangKey("name")}`]}
              label={t("t_studentClass")}
              trigger='onConfirm'
              onClick={() => {
                dispatch(push("studentClassCheckList"))
              }}
            >
              <Input readOnly />
            </Form.Item> */}
            <Form.Item
              name='studentClassNo'
              label={t("t_studentClassNo")}
              onClick={() => {
                dispatch(push("updateStudentClassNo"))
              }}
            >
              <Input readOnly />
            </Form.Item>
          </Form>
        </div>
      </App>
      <AccountUpdateFormStack
        visible={stackState.status.updateName}
        field={"name"}
        title={t("t_name")}
        value={studentInfo.name}
      />
      <AccountUpdateFormStack
        visible={stackState.status.updateMobilePhone}
        field={"mobile"}
        title={t("t_mobilePhone")}
        value={studentInfo.mobilePhone}
      />
      <AccountUpdateFormStack
        visible={stackState.status.updateStudentName}
        field={"student_name"}
        title={t("t_studentName")}
        value={studentInfo.studentName}
      />
      <AccountUpdateFormStack
        visible={stackState.status.updateStudentGender}
        formItem={
          <Selector
            className="genderSelector"
            columns={genderOptions.length}
            options={genderOptions}
          />
        }
        field={"student_gender"}
        title={t("t_studentGender")}
        value={studentInfo.studentGender}
      />
      <AccountUpdateFormStack
        visible={stackState.status.updateStudentClassNo}
        field={"student_grade"}
        title={t("t_studentClassNo")}
        value={studentInfo.studentClassNo}
      />
      <UpdatePasswordStack />
      {/* <StudentClassCheckListStack
        title={changeField(t("t_studentClass"))}
        updateMode
      /> */}
    </>
  );
};

export default MyAccount;

