import request from "../request";

export class UserAddressService {
  static get = async () => {
    return await request.get(`/user_address`);
  };

  static getById = async (addressId) => {
    return await request.get(`/user_address/${addressId}`);
  };

  static create = async (body) => {
    return await request.put(`/user_address`, {...body});
  };

  static update = async (body) => {
    return await request.post(`/user_address`, {...body});
  };

  static delete = async (addressId) => {
    return await request.del(`/user_address/${addressId}`);
  };
}