import { RiCloseLine } from "@remixicon/react"
import { Tag } from "antd-mobile"
import styles from "./FilterTag.module.scss"

const FilterTag = ({
  children,
  // key,
  backgroundColor,
  textColor = "var(--adm-tag-text-color)",
  onCancel
}) => {
  return (
    <Tag 
      // key={key} 
      color={backgroundColor}
    >
      <div className={styles.filterTag}>
        {children}
        <RiCloseLine 
          color={textColor} 
          onClick={(e) => { 
            onCancel(); 
            e.stopPropagation();
            // e.stopImmediatePropagation();
          }} 
        />
      </div>
    </Tag>
  )
}

export default FilterTag;