import React, { useEffect, useState } from 'react'
import { RiMenuLine } from '@remixicon/react';
import { Button, Popup } from 'antd-mobile'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LocalStorage } from '../../utils/LocalStorage';
import styles from './Menu.module.scss';
import ArrowButton from '../arrowBtn/ArrowButton';

import { useDispatch, useSelector } from 'react-redux';
import { push, setStackVisible } from '../../redux/tickStackSlice';
import { reRender } from '../../redux/tickPageSlice';
import { Helper } from '../../utils/Helper';

const Menu = ({
  visible,
  setVisible
}) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const stackStatus = useSelector((state) => state.tickStack.status)
  const dispatch = useDispatch()
  const [menuList, setMenuList] = useState([]);

  const navigateToPage = (page) => {
    navigate(page);
    dispatch(setStackVisible({ key: "menu", value: false }))
  }

  const loggedInMenuList = [
    {
      title: t("t_uniform"),
      onClick: () => { navigateToPage('/main/uniform') }
    },
    {
      title: t("t_shoppingBag"),
      onClick: () => { navigateToPage('/main/shoppingBag') }
    },
    {
      title: t("t_myOrder"),
      onClick: () => { navigateToPage('/main/order') }
    },
    {
      title: t("t_onlineHelp"),
      onClick: () => { navigateToPage('/customerService') }
    },
    {
      title: t("t_myAccount"),
      onClick: () => { navigateToPage('/account') }
    },
    {
      title: t("t_aboutSystem"),
      onClick: () => { navigateToPage('/aboutSystem') }
    },
    {
      title: t("t_logout"),
      onClick: () => { 
        LocalStorage.remove("token");
        LocalStorage.remove("student");
        window.location.reload(); 
        navigateToPage('/main/uniform');
      }
    }
  ]

  const logoutOutMenuList = [
    {
      title: t("t_entry"),
      onClick: () => { navigateToPage('/entry') }
    },
    {
      title: t("t_uniform"),
      onClick: () => { navigateToPage('/main/playground') }
    },
    {
      title: t("t_shoppingBag"),
      onClick: () => { navigateToPage('/main/playground') }
    },
    {
      title: t("t_onlineHelp"),
      onClick: () => { navigateToPage('/customerService') }
    },
    {
      title: t("t_aboutSystem"),
      onClick: () => { navigateToPage('/aboutSystem') }
    },
    {
      title: t("t_login"),
      onClick: () => { 
        dispatch(setStackVisible({ key: "menu", value: false })) 
        setTimeout(() => {
          dispatch(push("login")) 
        }, 500)
      }
    },
    {
      title: t("t_register"),
      onClick: () => { 
        dispatch(setStackVisible({ key: "menu", value: false })) 
        // dispatch(push("register")) 
        setTimeout(() => {
          dispatch(push("register")) 
        }, 500)
      }
    }
  ]

  useEffect(() => {
    if (LocalStorage.get("token").isNull()) {
      setMenuList(logoutOutMenuList);
    } else {
      setMenuList(loggedInMenuList);
    }
  }, [stackStatus])

  return (
    <>
      <Button className={styles.menuButton} onClick={() => { dispatch(setStackVisible({ key: "menu", value: true })) }}>
        <RiMenuLine />
      </Button>
      <Popup
        bodyClassName={styles.menu}
        visible={stackStatus["menu"]}
        bodyStyle={{ height: '100%', width: "65%", justifyContent: "center" }}
        position='right'
        getContainer={null}
        onMaskClick={() => { dispatch(setStackVisible({ key: "menu", value: false })); }}
      >
        <div className={styles.menuContainer}>
          <MenuHeader title={t("t_schoolTitle", { schoolName: LocalStorage.get("school").toObject()[Helper.toLangKey("name")] })} setVisible={setVisible} />
          { 
            menuList.length > 0 && 
            menuList.map((m, i) => (
              <ArrowButton 
                key={i} 
                title={m.title} 
                textColor={"var(--app-menu-text-color)"}
                lineColor={"var(--app-menu-text-color)"}
                backgroundColor={"transparent"} 
                onClick={m.onClick}
                fontSize={"1.9vh"}
                height={"5.5vh"}
              />
            ))
          }
        </div>
      </Popup>
    </>
  )
}

export const MenuHeader = ({ title, setVisible }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);

  const changeLang = (lang) => {
    LocalStorage.set("lang", lang)
    dispatch(reRender());
    dispatch(setStackVisible({ key: "menu", value: false }))
  }

  return (
    <div className={styles.menuHeaderContainer}>
      <p 
        onClick={() => {
          if (count === 5) {
            setCount(0);
            dispatch(setStackVisible({ key: "menu", value: false }))
            navigate("/main/unitTest");
            return;
          }
          setCount(count + 1);
        }}
      >{ title || "Menu Header" }</p>
      <div className={styles.langContainer}>
        <Button size="mini" onClick={() => { changeLang("tc_hk") }}>中文</Button>
        <Button size="mini" onClick={() => { changeLang("en") }}>ENG</Button>
      </div>
    </div>
  )
}

export default Menu