import i18n from "../i18n";

export const genderOptions = [
  {
    label: i18n.t("t_male"),
    value: 'B',
  },
  {
    label: i18n.t("t_female"),
    value: 'G',
  },
]

export const Gender = {
  'B': { 
    label: i18n.t("t_male"), 
    backgroundColor: "var(--app-gender-male-color)"
  },
  'G': { 
    label: i18n.t("t_female"), 
    backgroundColor: "var(--app-gender-female-color)"
  }
}