import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, Toast, Modal, DotLoading } from "antd-mobile";
import Stack from "../Stack";
import { EyeInvisibleOutline, EyeOutline } from 'antd-mobile-icons'
import { AuthService } from "../../../service/apis/AuthService";
import { LocalStorage } from "../../../utils/LocalStorage";
import { useDispatch, useSelector } from "react-redux";
import { setStackVisible } from "../../../redux/tickStackSlice";
import { useNavigate } from 'react-router-dom';

const UpdatePasswordStack = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const stackState = useSelector((state) => state.tickStack);
  const navigate = useNavigate();
  const [form] = Form.useForm()
  const [existingPasswordVisible, setExistingPasswordVisible] = useState(false)
  const [newPasswordVisible, setNewPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
  const pleaseEnterField = (field) => t("t_pleaseEnterField", { field })

  const onFinish = async (value) => {
    Modal.show({
      bodyClassName: "loading",
      content: <DotLoading color='primary' style={{ '--size': '32px' }} />,
      closeOnMaskClick: true,
    })
    const data = {
      column: "password",
      value: value.newPassword
    }
    const res = await AuthService.updateUser(data)
    if (res.success) {
      Modal.clear()
      Modal.alert({
        content: "修改成功 請重新登入",
        confirmText: t("t_confirm"),
        onConfirm: () => {
          LocalStorage.remove("token");
          navigate('/main/uniform');
          window.location.reload();
        }
      })
    } else {
      Modal.clear()
      Toast.show({
        icon: 'fail',
        content: t("t_updateFail"),
        position: 'center',
      })
    }
  }

  const confirmPasswordChecking = () => {
    const { newPassword, confirmPassword } = form.getFieldsValue()
    if (!confirmPassword) {
      return Promise.resolve()
    }
    if (newPassword === confirmPassword) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(t("t_confirmPasswordChecking")))
  }

  return (
    <>
      <Stack
        title={t("t_changeField", t("t_password"))}
        visible={stackState.status.updatePassword}
      >
        <div style={{ width: "100%" }}>
          <Form
            form={form}
            onFinish={onFinish}
            footer={
              <Button block type='submit' color='primary' size='large'>
                {t("t_update")}
              </Button>
            }
          >
            <Form.Item
              name='password'
              label={t("t_existingPassword")}
              extra={
                <div >
                  {!existingPasswordVisible ? (
                    <EyeInvisibleOutline onClick={() => setExistingPasswordVisible(true)} />
                  ) : (
                    <EyeOutline onClick={() => setExistingPasswordVisible(false)} />
                  )}
                </div>
              }
              rules={[
                {
                  required: true,
                  message: pleaseEnterField(t("t_existingPassword"))
                },
              ]}
            >
              <Input type={existingPasswordVisible ? 'text' : 'password'} placeholder={pleaseEnterField(t("t_existingPassword"))} />
            </Form.Item>
            <Form.Item
              name='newPassword'
              label={t("t_newPassword")}
              extra={
                <div >
                  {!newPasswordVisible ? (
                    <EyeInvisibleOutline onClick={() => setNewPasswordVisible(true)} />
                  ) : (
                    <EyeOutline onClick={() => setNewPasswordVisible(false)} />
                  )}
                </div>
              }
              rules={[
                {
                  required: true,
                  message: pleaseEnterField(t("t_newPassword"))
                },
                {
                  pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,12}$/,
                  message: t("t_passwordRule")
                }
              ]}
            >
              <Input type={newPasswordVisible ? 'text' : 'password'} placeholder={pleaseEnterField(t("t_newPassword"))} />
            </Form.Item>
            <Form.Item
              name='confirmPassword'
              label={t("t_confirmPassword")}
              extra={
                <div >
                  {!confirmPasswordVisible ? (
                    <EyeInvisibleOutline onClick={() => setConfirmPasswordVisible(true)} />
                  ) : (
                    <EyeOutline onClick={() => setConfirmPasswordVisible(false)} />
                  )}
                </div>
              }
              rules={[
                {
                  required: true,
                  message: pleaseEnterField(t("t_confirmPassword"))
                },
                {
                  validator: confirmPasswordChecking
                }
              ]}
            >
              <Input type={confirmPasswordVisible ? 'text' : 'password'} placeholder={pleaseEnterField(t("t_confirmPassword"))} />
            </Form.Item>
          </Form>
        </div>
      </Stack>
    </>
  );
};

export default UpdatePasswordStack;
