import { useTranslation } from "react-i18next";
import { NavBar, Button, ErrorBlock   } from "antd-mobile";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ShoppingBag.module.scss";
import ShoppingBagCard from "../../components/shoppingBagCard/ShoppingBagCard";
import { useSelector } from 'react-redux';
import shoppingBagEmpty from "../../assets/icons/shoppingBagEmpty.svg"
import TotalSumBottomBar from "../../components/totalSumBottomBar/TotalSumBottomBar";
import ChooseUniformStack from "../../components/stack/chooseUniform/ChooseUniformStack";
import CheckoutStack from "../../components/stack/checkout/CheckoutStack";
import { LocalStorage } from "../../utils/LocalStorage";
import { Log } from "../../utils/Log";
import { useDispatch } from "react-redux";
import { push, setStackVisible } from "../../redux/tickStackSlice";
import { UtilityService } from "../../service/apis/UtilityService";
import Response from "../../utils/Response";

const TAG = "ShoppingBag";

const ShoppingBag = () => {
  const { t } = useTranslation();
  const [isEditMode, setIsEditMode] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const pageState = useSelector((state) => state.tickPage);
  // const [chooseUniformStackVisible, setChooseUniformStackVisible] = useState(false);
  // const [checkoutStackVisible, setCheckoutStackVisible] = useState(false)
  const tempUniformSetState = useSelector((state) => state.tickTempUniformSet.set);

  const [itemSets, setItemSets] = useState([]); 

  useEffect(() => {
    if (LocalStorage.get("shoppingBag").toObject().length <= 0) setItemSets([]);
    Log(TAG, LocalStorage.get("shoppingBag").toObject())
    setItemSets(LocalStorage.get("shoppingBag").toObject())
  }, [pageState.render, tempUniformSetState, LocalStorage.get("shoppingBag").value])

  const onCheckoutClick = () => {
    if (LocalStorage.get("token").isNull()) {
      dispatch(push("login"))
    } else {     
      dispatch(push("checkout"))
    }
  }

  return (
    <>
      <div className="top">
        <NavBar className="nav" onBack={() => navigate("/main/uniform")} 
          right={
            <Button color='primary' fill='none' onClick={() => setIsEditMode(!isEditMode)} style={{padding: '10px 5px'}}>
              {isEditMode ? t("t_exitManage") : t("t_manage")}
            </Button> 
          }>
          {t("t_shoppingBag")}
        </NavBar>
      </div>
      <div className="body">
        <div className={styles.shoppingBagContainer}>
          <div className={styles.upperContainer}>
            {
              itemSets.length > 0 ?
              itemSets.map((item, index) => {
                // Log(TAG, item)
                return (
                  <ShoppingBagCard 
                    key={index} 
                    setIndex={index} 
                    setInfo={item} 
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                    setChooseUniformStackVisible={() => { dispatch(push("chooseUniform")) }}
                  />
                )
              })
              :
              <ErrorBlock 
                image={shoppingBagEmpty}
                status='empty' 
                title={t("t_shoppingBagIsEmpty")} 
                description=''
                style={{ marginTop: '20px' }}
              />
            }
          </div>
          <div className={styles.lowerContainer}>
            <TotalSumBottomBar itemSets={itemSets} onClick={onCheckoutClick}/>
          </div>
        </div>
      </div>
      <ChooseUniformStack />
      <CheckoutStack itemSets={itemSets} />
    </>
  )
}

export default ShoppingBag;