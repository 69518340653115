import ItemSet from "./entity/ItemSet";
import { LocalStorage } from "./LocalStorage";

export class Helper {

  static langShortForm(c) {
    let r = "";
    switch(LocalStorage.get("lang").toString()) {
      case "tc_hk":
        r = "Tc";
        break;
      case "en":
        r = "En";
        break;
      default:
        r = "Tc";
        break
    }
    return c === "upper" ? r : r.toLowerCase();
  }

  static toLangKey(key) {
    if (!key) return this.langShortForm("lower");
    return `${key}_${this.langShortForm("lower")}`;
  }

  static getGradingCategoryByGradeKey(code) {
    const educations = LocalStorage.get("school").toObject().education;
    for (let education of educations) {
      for (let grade of education.grades) {
        if (grade.code === code.toUpperCase()) {
          return grade;
        }
      }
    }
    return null; // not found
  }

  static getGenderOptions() {
    const genders = LocalStorage.get("school").toObject().gender;
    let options = [];
    for (let [key, value] of Object.entries(genders)) {
      options.push({ label: value[`${this.toLangKey("name")}`], value: key })
    }
    return options;
  }

  static getSeasonOptions() {
    const seasons = LocalStorage.get("school").toObject().season;
    let options = [];
    for (let [key, value] of Object.entries(seasons)) {
      options.push({ label: value[`${this.toLangKey("name")}`], value: key })
    }
    return options;
  }

  static formatNumberWithCommas(num) {
    return num.toLocaleString();
  }

  static addFilterKeys(array) {
    let result = [];
    for (let set of array) {
      let itemSet = new ItemSet(set)
      result.push(itemSet)
    }
    return result;
  }

  static filteredData(array, argGender, argSeason, argEducation) {
    argGender = argGender ?? [];
    argSeason = argSeason ?? [];
    argEducation = argEducation ? argEducation.split('') : [];

    const result = [];
    for (let set of array) {
      let genderCondition;
      let seasonCondition;
      let educationCondition;
  
      // gender logic
      if (
        argGender.length === 0 ||
        argGender.includes(set.gender_code) || 
        set.gender_code === 'U'
        ) {
        genderCondition = true;
      } else { 
        genderCondition = false;
      }

      // season logic
      if (
        argSeason.length === 0 ||
        argSeason.includes(set.season_code) || 
        set.season_code === 'A'
        ) {
        seasonCondition = true;
      } else { 
        seasonCondition = false;
      }

      // education logic
      if (argEducation.length === 0 || argEducation.includes(set.education_code)) {
        educationCondition = true;
      } else { 
        educationCondition = false;
      }

      if (genderCondition && seasonCondition && educationCondition) {
        result.push(set)
      }
    }
    return result;
  }

  static mapTransWithMasterData(trans, masterData) {
    if (!masterData) return trans;
    for (let tran of trans) {
      for (let line of tran.lines) {

        // insert size tc & en to line
        let size = line.item_sku.slice(10, 13);
        for (let mData in masterData.size_code) {
          if (size === mData) {
            line.size_tc = masterData.size_code[mData].name_tc;
            line.size_en = masterData.size_code[mData].name_en;
            break;
          }
          line.size_tc = size;
          line.size_en = size;
        }

        // insert color tc & en to line
        let color = line.item_sku.slice(8, 10);
        for (let mData in masterData.color_code) {
          if (color === mData) {
            line.color_tc = masterData.color_code[mData].name_tc;
            line.color_en = masterData.color_code[mData].name_en;
            break;
          }
          line.color_tc = color;
          line.color_en = color;
        }
      }
    }
    return trans;
  }

  // For StackSlice
  static peek(stack) {
    if (!stack) return null;
    if (stack.length <= 0) return null;
    return stack.at(stack.length - 1);
  }

  static getRecommendations() {
    const itemSets = LocalStorage.get("itemSets").toObject();
    if (!itemSets || itemSets?.length <= 0) return [];

    const shoppingBag = LocalStorage.get("shoppingBag").toObject();
    if (!shoppingBag && shoppingBag.length <= 0) return []; // error

    // const res = await ItemSetService.get();
    // new Response(res).handle((data) => {
    //   if (!data || data?.length <= 0) return;
    // })
    const purchasedMap = {};
    shoppingBag.forEach((set) => {
      purchasedMap[set.code] = set;
    })

    const recommendations = [];
    itemSets.forEach((set) => {
      if (!purchasedMap[set.code]) {
        recommendations.push(set);
      }
    })
    LocalStorage.set("recommendations", JSON.stringify(recommendations))
    return LocalStorage.get("recommendations").toObject();
  }
}