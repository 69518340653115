import request from "../request";

export class UtilityService {
  
  static get = async (code) => {
    return await request.get(`/base58/${code}`);
  };

  static post = async (body) => {
    return await request.post(`/base58`, body);
  };

}