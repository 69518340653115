import { Navigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { LocalStorage } from "../utils/LocalStorage";

export const ProtectedRoute = ({ children }) => {
  const schoolCode = LocalStorage.get("schoolCode");
  const school = LocalStorage.get("school");

  if (schoolCode.isNull() || school.isNull()) {
    return <Navigate to="/accessDenied" replace />
  }

  return children;
};
