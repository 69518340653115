import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Toast, TextArea, Modal, DotLoading } from "antd-mobile";
import Stack from "../Stack";
import { UserAddressService } from "../../../service/apis/UserAddressService";
import { useDispatch, useSelector } from "react-redux";
import { Helper } from "../../../utils/Helper";
import { pop } from "../../../redux/tickStackSlice";

const UpdateDeliveryAddressStack = ({
  addressId,
  addressText
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stackState = useSelector((state) => state.tickStack);
  
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ deliveryAddress: addressText })
  }, [addressText])

  const closeSelf = () => {
    if (Helper.peek(stackState.stacks) === "updateDeliveryAddress") dispatch(pop());
  }

  const onFinish = async (value) => {
    if (!value.deliveryAddress) {
      Toast.show({
        icon: 'fail',
        content: t("t_pleaseEnter"),
        position: 'center',
      })
      return
    }
    Modal.show({
      bodyClassName: "loading",
      content: <DotLoading color='primary' style={{ '--size': '32px' }} />,
      closeOnMaskClick: true,
    })
    const data = {
      address: value.deliveryAddress,
      address_id: addressId
    }
    const res = await UserAddressService.update(data)
    if (res.success) {
      Modal.clear()
      Toast.show({
        icon: 'success',
        content: t("t_updateSuccess"),
        position: 'center',
      })
      closeSelf();
      form.resetFields()
    } else {
      Modal.clear()
      Toast.show({
        icon: 'fail',
        content: t("t_updateFail"),
        position: 'center',
      })
    }
  }
  
  return (
    <Stack
      title={`${t("t_change")}${t("t_deliveryAddress")}`}
      visible={stackState.status.updateDeliveryAddress}
    >
      <div style={{ width: "100%" }}>
        <Form
          form={form}
          footer={
            <Button block type='submit' color='primary' size='large'>
              {t('t_update')}
            </Button>
          }
          onFinish={onFinish}
        >
          <Form.Item
            name={"deliveryAddress"}
            label={t("t_deliveryAddress")}
          >
            <TextArea
              id="updateDeliveryAddress"
              showCount
              maxLength={50}
              placeholder={`${t("t_pleaseEnter")}${t("t_deliveryAddress")}`}
            />
          </Form.Item>
        </Form>
      </div>
    </Stack>
  );
};

export default UpdateDeliveryAddressStack;