import { useEffect, useState } from "react";
import { LocalStorage } from "../../../utils/LocalStorage";
import { Log } from "../../../utils/Log";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { push, pop } from "../../../redux/tickStackSlice";
import UniformSquareButton from "../../uniformSquareButton/UniformSquareButton";
import { ItemSetService } from "../../../service/apis/ItemSetService";
import { Helper } from "../../../utils/Helper";
import { setData } from "../../../redux/tickTempUniformSet";
import Stack from "../Stack";
import styles from "./RecommendationStack.module.scss";
import Response from "../../../utils/Response";

const TAG = "RecommendationStack";

const RecommendationStack = () => {
  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stackStatus = useSelector((state) => state.tickStack.status);

  // const [itemSets, setItemSets] = useState([]);
  
  // const fetchItemSets = async () => {
  //   setItemSets(LocalStorage.get("recommendations").toObject())
  // }

  // useEffect(() => {
  //   if (stackStatus.recommendation) fetchItemSets();
  // }, [stackStatus.recommendation])

  // useEffect(() => {
  //   Log(TAG, itemSets)
  // }, [itemSets])

  useEffect(() => {
    return () => { LocalStorage.remove("recommendations") }
  }, [])
  
  return (
    <Stack
      title={t("t_youMayAlsoNeed")}
      visible={stackStatus.recommendation}
    >
      <div className={styles.recommendationContainer}>
        {
          LocalStorage.get("recommendations").toObject()?.map((item, index) => {
            return (
              <UniformSquareButton
                key={item.code}
                title={item[Helper.toLangKey("name")]}
                image={item.images}
                onClick={() => {
                  // setChooseUniformVisible(true);
                  dispatch(pop());
                  dispatch(push("chooseUniform"))
                  dispatch(setData(item));
                }}
              />
            )
          })
        }
      </div>
    </Stack>
  )
}

export default RecommendationStack;