import { createSlice } from "@reduxjs/toolkit";
import { Log } from "../utils/Log";

export const initialState = {
  title: "",
  params: {
    uniformPage: {
      education: null,
      gender: [],
      season: []
    },
    entryPage: {},
  },
  render: false
};

export const tickPageSlice = createSlice({
  name: "tickPage",
  initialState: {
    title: "",
    params: {
      uniformPage: {
        education: null,
        gender: [],
        season: []
      },
      entryPage: {},
    },
    render: false
  },
  reducers: {
    setTitle: (state, newState) => {
      Log("TickPageSlice", "setTitle")
      state.title = newState.payload;
    },
    setUniformPageParam: (state, newState) => {
      Log("TickPageSlice", "setUniformPageParam")
      Log("TickPageSlice", newState.payload)
      state.params.uniformPage = { ...state.params.uniformPage, ...newState.payload };
    },
    setEntryPageParam: (state, newState) => {
      Log("TickPageSlice", "setEntryPageParam")
      state.params.entryPage = { ...state.params.entryPage, ...newState.payload };
    },
    reRender: (state) => {    
      state.render = !state.render;
    },
    reset: () => initialState,
  },
});

export const { 
  setTitle, 
  setUniformPageParam,
  setEntryPageParam,
  reRender,
  reset 
} = tickPageSlice.actions;

export default tickPageSlice.reducer;
