export default class Base {
  constructor(item) {
    this.active = item.active;
    this.created_by = item.created_by;
    this.created_on = item.created_on;
    this.modified_by = item.modified_by;
    this.modified_on = item.modified_on;
    this.deleted = item.deleted;
    this.deleted_by = item.deleted_by;
    this.deleted_on = item.deleted_on;
  }
}