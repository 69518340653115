import { App } from "antd"
import { ErrorBlock } from "antd-mobile"
import Logo from "../../assets/images/logo.png";
import styles from "./AccessDenied.module.scss"
import { useTranslation } from "react-i18next";

const AccessDenied = () => {
  const { t } = useTranslation();
  return (
    <App>
      <div className={styles.accessDeniedContainer}>
        <ErrorBlock 
          image={Logo}
          status='empty'
          title={t("t_accessDenied")}
          description=""
        />
      </div>
    </App>
  )
}

export default AccessDenied;