import React, { useEffect, useState } from "react";
import { TabBar } from "antd-mobile";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { 
  RiShoppingBagLine,
  RiShirtLine,
  RiFileListLine
} from "@remixicon/react";
import styles from "./BottomNavBar.module.scss";
import { LocalStorage } from "../../utils/LocalStorage";
import ItemSet from "../../utils/entity/ItemSet";
import { useDispatch, useSelector } from 'react-redux';
import { push } from "../../redux/tickStackSlice";

const BottomNavBar = () => {
  const dispatch = useDispatch();
  const tempUniformSetState = useSelector((state) => state.tickTempUniformSet.set);
  const navigate = useNavigate();
  const location = useLocation();

  const [totalQty, setTotalQty] = useState(0);
  const { t } = useTranslation();
  const { pathname } = location;  

  const setRouteActive = (value) => {
    if (value === "/main/order" && LocalStorage.get("token").isNull() ) {
      dispatch(push("login"));
      return;
    }
    navigate(value);
  };

  const getTotalQty = () => {
    let totalQtyCount = 0;
    let sets = LocalStorage.get("shoppingBag").toObject();

    if (LocalStorage.get("shoppingBag").toObject().length > 0) {
      for (let item of sets) {
        let set = new ItemSet(item);
        let { qty } = set.total();
        totalQtyCount += qty;
      }
    }
    return totalQtyCount;
  }

  useEffect(() => {
    setTotalQty(getTotalQty());
  }, [Object.keys(tempUniformSetState).length, LocalStorage.get("shoppingBag").value])

  const tabs = [
    {
      key: "/main/shoppingBag",
      title: t("t_shoppingBag"),
      icon: <>
        <RiShoppingBagLine />
        {
          totalQty > 0 ?
          <div className={styles.shoppingBagQty}>
            {
              totalQty < 100 ? totalQty : '99+'
            }
          </div>
          :
          null
        }
      </>,
    },
    {
      key: "/main/uniform",
      title: t("t_uniform"),
      icon: <RiShirtLine />,
    },
    {
      key: "/main/order",
      title: t("t_order"),
      icon: <RiFileListLine />,
    },
    // {
    //   key: "/main/playground",
    //   title: "Playground",
    //   icon: <AppstoreOutline />
    // }
  ];

  return (
    <TabBar activeKey={pathname} onChange={(value) => setRouteActive(value)}>
      {tabs.map((item) => (
        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
      ))}
    </TabBar>
  );
};

export default BottomNavBar;
