import React, { useEffect } from 'react';
import { App } from 'antd';
import { Log } from '../../utils/Log';
import { Result, ResultPage } from 'antd-mobile';
import { useTranslation } from 'react-i18next';
import { Helper } from '../../utils/Helper';
import { useNavigate } from 'react-router';
import { LocalStorage } from '../../utils/LocalStorage';

const TAG = "PaymentFailure";

const PaymentFailure = ({
  totalAmount,
  details
}) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    LocalStorage.set("shoppingBag", JSON.stringify([]));
  }, [])

  return (
    <App>
      <ResultPage
        className={"paymentFailure"}
        status={"error"}
        title={
          <div style={{ fontSize: 15 }}>
            {t("t_paymentFailure")}
          </div>
        }
        description={
          <>
            <span style={{ fontSize: 22, color: '#ffffff', marginRight: 4 }}>
              HK$
            </span>
            <span style={{ fontSize: 48, color: '#ffffff' }}>{Helper.formatNumberWithCommas(totalAmount)}</span>
          </>
        }
        details={details}
        secondaryButtonText={ t("t_backToHomepage")}
        primaryButtonText={t("t_retryPayment")}
        onSecondaryButtonClick={() => { navigate("/main/uniform") }}
        onPrimaryButtonClick={null}
      >
        <Result
          status={"error"}
          title={t("t_transactionCancellation")}
          description={t("t_paymentFailureMsg")}
        />
      </ResultPage>
    </App>
  )
}

export default PaymentFailure