import { useTranslation } from "react-i18next";
import { NavBar, List } from "antd-mobile";
import { useState, useEffect, useRef } from "react";
import Stack from "../../components/stack/Stack";
import { App } from "antd"
import Menu, { MenuHeader } from "../../components/menu/Menu";
import { useNavigate } from "react-router-dom";
import styles from "./AboutSystem.module.scss";
import ArrowButton from "../../components/arrowBtn/ArrowButton";
import packageJson from "../../../package.json"
import { AboutSystemService } from "../../service/apis/AboutSystemService";
import { Helper } from "../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { push } from "../../redux/tickStackSlice";

const AboutSystem = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stackStatus = useSelector((state) => state.tickStack.status);

  const [pressCount, setPressCount] = useState(0);
  const [lastPressTime, setLastPressTime] = useState(0);
  
  const termsOfUseRef = useRef();
  const privacyPolicyRef = useRef();

  const lang = Helper.langShortForm();

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    if (pressCount === 5) {
      navigate('/main/unitTest');
    }
  }, [pressCount]);

  const getData = async () => {
    const res = await AboutSystemService.get()
    if (res.success) {
      // setPrivacyPolicyText(res.data?.privacy)
      // setTermsOfUseText(res.data?.t_and_c)
      if (termsOfUseRef.current) termsOfUseRef.current.innerHTML = res.data?.t_and_c[lang]
      if (privacyPolicyRef.current) privacyPolicyRef.current.innerHTML = res.data?.privacy[lang]
    }
  }

  const handleButtonPress = () => {
    const currentTime = new Date().getTime();
    if (currentTime - lastPressTime < 1000) {
      setPressCount(prevCount => prevCount + 1);
    } else {
      setPressCount(1);
    }
    setLastPressTime(currentTime);
  };

  return (
    <App>
      <div className="app">
        <div className="top">
          <NavBar className="nav" backArrow onBack={() => navigate("/main/uniform")} right={<Menu />}>
            {t("t_aboutSystem")}
          </NavBar>
        </div>
        <div className="body">
          <div className={styles.aboutSystemContainer}>
            <div className={styles.titleText}>{t("t_qrCodeOnlineUniformOrderSystem")}</div>
            <div className={styles.versionText} onClick={handleButtonPress}>{t("t_systemVersion")}</div>
            <div className={styles.versionText} onClick={handleButtonPress}>{packageJson.version}</div>
            <div className={styles.companyRightText}>{t("t_companyRightsText")}</div>
            <div className={styles.systemInfoContainer}></div>
            <ArrowButton title={t("t_termsOfUse")} onClick={() => { dispatch(push("termsOfUse")) }}></ArrowButton>
            <ArrowButton title={t("t_privacyPolicy")} onClick={() => { dispatch(push("privacyPolicy")) }}></ArrowButton>
          </div>
          <Stack title={t("t_termsOfUse")} visible={stackStatus.termsOfUse}>
            <div ref={termsOfUseRef} style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", padding: "20px" }} />
          </Stack>
          <Stack title={t("t_privacyPolicy")} visible={stackStatus.privacyPolicy}>
            <div ref={privacyPolicyRef} style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", padding: "20px" }} />
          </Stack>
        </div>
      </div>
    </App>
  )
}

export default AboutSystem;