import { App } from 'antd';
import Logo from "../../assets/images/logo.png";
import styles from "./Landing.module.scss";
import { ProgressBar, Space } from 'antd-mobile';
import { useEffect, useState } from 'react';
import { loadingFillColor, loadingTrackColor } from '../../configs/setting';
import { useNavigate, useParams } from 'react-router-dom';
import { LocalStorage } from '../../utils/LocalStorage';
import { SchoolService } from '../../service/apis/SchoolService';
import Response from '../../utils/Response';
import { useDispatch } from 'react-redux';
import { setStackVisible } from '../../redux/tickStackSlice';
import { Log } from '../../utils/Log';
import { AuthService } from '../../service/apis/AuthService';
import { setEntryPageParam } from '../../redux/tickPageSlice';
import { Helper } from '../../utils/Helper';
import { setColorTheme } from '../../components/theme/Theme';
import { UtilityService } from '../../service/apis/UtilityService';

const TAG = "Landing";

const Landing = () => {
  const [percent, setPercent] = useState(0);
  const [checked, setChecked] = useState(false);
  const { schoolCode } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchSchoolData = async () => {
    LocalStorage.set("schoolCode", schoolCode);
    const res = await AuthService.user();
    /**
     * 1. User with student -> nav to uniform page
     * 2. User without student -> nav to entry -> entry page should disable the user name field
     * 3. Error -> Access denied
     */
    new Response(res).handle((data) => {
      // School code found
      setTimeout(() => {
        const students = data["students_json"];
        LocalStorage.set("school", JSON.stringify(data))
        if (Object.keys(students).length <= 0) {
          // No student
          navigate("/entry")
        } else {
          navigate("/main/uniform")
        }
        dispatch(setStackVisible({ key: "loading", value: false }));
      }, 1000)
    }, (data) => {  
      /**
       * TODO: check whether the school code or the token is invalid
       */
      setTimeout(() => {
        LocalStorage.remove("school");
        LocalStorage.remove("schoolCode");
        navigate(`/accessDenied/${schoolCode}`)
      }, 1000)
    })  
  }

  const checkSchoolCode = async () => {
    if (LocalStorage.get("schoolCode").isNull()) {
      LocalStorage.set("schoolCode", schoolCode);
    } else {
      const res = await SchoolService.get();
      if (res.success) {
        LocalStorage.set("tempSchoolCode", LocalStorage.get("schoolCode").toString())
      }
    }
    setChecked(true);
  }

  const auth = async () => {
    const res = await SchoolService.get();
    if (res.success) {
      setColorTheme(res.data["color_theme"])
      LocalStorage.set("school", JSON.stringify(res.data));
      LocalStorage.set("popupState", 1)
      const token = LocalStorage.get("token");
      const entryStudent = LocalStorage.get("student")
      if (token.isNull()) {
        if (entryStudent.isNull()) {
          setTimeout(() => {
            dispatch(setStackVisible({ key: "loading", value: false }));
            navigate("/entry")
          }, 1000)
        }else {
          setTimeout(() => {
            dispatch(setStackVisible({ key: "loading", value: false }));
            if (window.location.pathname.includes("code")) {
              navigate("/main/uniform");
            } else {
              navigate(window.location.pathname)
            }
          }, 1000)
        }
      } else {
        Log(TAG, `School code ${schoolCode || "loading ..."}`)
        /**
         * 1. User with student -> nav to uniform page
         * 2. User without student -> nav to entry -> entry page should disable the user name field
         * 3. Error -> Access denied
        */
        const user = await AuthService.user();
        if (user.success) {
          setTimeout(() => {
            dispatch(setStackVisible({ key: "loading", value: false }));
            if (user.data.student) {
              const student = user.data.student;
              // const grade = Helper.getGradingCategoryByGradeKey(student["student_grade"].toUpperCase())
              dispatch(
                setEntryPageParam({ 
                  name: user.data.name, 
                  studentName: student["student_name"], 
                  studentGender: [student["student_gender"]],
                  // studentClass: {
                  //   key: grade.code,
                  //   category_id: grade["category_id"],
                  //   name_tc: grade["name_tc"],
                  //   name_en: grade["name_en"]
                  // }
                  studentGrade: student["student_grade"],
                }))
                // if (window.location.pathname.includes("code")) {
                //   navigate("/main/uniform");
                // } else {
                //   navigate(window.location.pathname)
                // }
                navigate("/main/uniform");
            } else {
              navigate("/entry")
            }
          }, 1000)
        }
      }

    } else {
      setTimeout(() => {
        navigate(`/pageNotFound`)
      }, 1000)
    }
    filterShoppingBag()
  }

  /**
   * Shopping bag should only consist of the item sets of the current school
   */
  const filterShoppingBag = async () => {
    const shoppingBag = LocalStorage.get("shoppingBag").toObject();
    const res = await UtilityService.get(LocalStorage.get("schoolCode").toString());
    new Response(res).handle((data) => {
      const schoolCode = data.school_code;
      Log(TAG, schoolCode)
      const newBag = shoppingBag.filter((set) => set.school_code === schoolCode)
      LocalStorage.set("shoppingBag", JSON.stringify(newBag))
    })
  }

  useEffect(() => {
    checkSchoolCode();
  }, [])

  useEffect(() => {
    if (!checked) return;

    // If no school code param in URL and has schoolCode in localStorage
    if (checked && schoolCode) LocalStorage.set("schoolCode", schoolCode);
    auth();
  }, [checked]) 

  
  useEffect(() => {
    const progress = setInterval(() => {
      setPercent(percent + 5)
    }, 25)

    return () => { 
      clearInterval(progress); 
    }
  }, [percent])

  return (
    <App>
      <div className={styles.container}>
        <Space block direction='vertical'>
          <img src={Logo} alt="mds_icon" />
          <ProgressBar
            percent={percent}
            style={{
              '--fill-color': loadingFillColor,
              '--track-color': loadingTrackColor
            }}
          /> 
        </Space>
      </div>
    </App>
  )
}

export default Landing