import { useNavigate } from "react-router-dom";
import { Button, NavBar, Space } from "antd-mobile";
import { useState, useEffect } from "react";
import Stack from "../../components/stack/Stack";
import Block from "../../components/block/Block";
import styles from "./Playground.module.scss";
import Menu from "../../components/menu/Menu";
import { useDispatch, useSelector } from "react-redux";
import { push, setStackVisible } from "../../redux/tickStackSlice";
import { APP as env, endpoint } from "../../configs/env";
import { LocalStorage } from "../../utils/LocalStorage";
import { setTitle } from "../../redux/tickPageSlice";
import { useTranslation } from "react-i18next";

const Playground = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stackStatus = useSelector((state) => state.tickStack.status);
  const { t } = useTranslation();

  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);

  useEffect(() => {
    dispatch(setTitle("unitTest"))
  }, [])

  return (
    <>
      <div className="top">
        <NavBar className="nav" backArrow={false} right={<Menu />}>
          { t("t_unitTest") }
        </NavBar>
      </div>
      <div className="body">
        <div className={styles.playgroundContainer}>
          <Block title={"Version"}><h3>{env.version}</h3></Block>
          <Block title={"Environment"}><h3>{env.env}</h3></Block>
          <Block title={"Base Url"}><h3>{endpoint.baseUrl}</h3></Block>
          <Block title={"API Url"}><h3>{endpoint.apiUrl}</h3></Block>
          <Block title={"Language"}><h3>{LocalStorage.get("lang").toString()}</h3></Block>
          <Block title="Button">
            <Space>
              <Button size='middle' color='primary' onClick={() => { 
                // dispatch(setStackVisible({ key: "login", value: true })) 
                dispatch(push("login"))
              }}>
                Login
              </Button>
              <Button 
                size='middle'
                color='danger' 
                onClick={() => { 
                  LocalStorage.remove("token");
                  navigate('/main/uniform');
                  window.location.reload(); 
                }}
              >
                Logout
              </Button>
              <Button size='middle' color='success'>
                Success
              </Button>
              <Button size='middle' color='warning'>
                Warning
              </Button>
            </Space>
          </Block>
          <Block title="Stack">
            <Space wrap>
              <Button 
                size='middle' 
                color='primary' 
                onClick={() => { setVisible1(true); navigate('/main/unitTest') }}>
                Single Stack
              </Button> 
              <Button 
                size='middle' 
                color='success' 
                onClick={() => { 
                  // setVisible2(true); 
                  // navigate("/main/unitTest/stack1")
                  window.history.pushState(null, null, "/main/unitTest/stack1")
                }}>
                Stack by Stack
              </Button>
              <Button size='middle' color='success' onClick={() => { 
                // dispatch(setStackVisible({ key: "login", value: true })) 
                dispatch(push("login"))
              }}>
                Show Login Stack
              </Button>
            </Space>
          </Block>
          <Block title="Navigate to page">
            <Space wrap>
              <Button size='middle' color='primary' onClick={() => { navigate("/entry") }}>
                Entry Form
              </Button>
              <Button size='middle' color='primary' onClick={() => { navigate("/customerService") }}>
                Customer Service
              </Button>
              <Button size='middle' color='primary' onClick={() => { navigate("/aboutSystem") }}>
                About System
              </Button> 
            </Space>
          </Block>
          <Block title="Menu">
            <Space>
              <Menu />
            </Space>
          </Block>
          <Block title="Payment Result">
            <Space wrap>
              <Button size='middle' color='success' onClick={() => { navigate("/payment/86VkjxZNKtuyoiwfM2pZHMvikEsmQpUdw2gCBEVfAb3DE8qziNhzDAdwr3dL44t34JfdYvXxi5GmpVmvYi5BVvBvBF6Dwwfg1rGUTTAKsiy5kxU7J3dxtFDDKYu8Ltgdm41XyCmUf5Ag4") }}>
                Payment Success
              </Button> 
              <Button size='middle' color='danger' onClick={() => { navigate("/payment/86VkjxZNKtuyoiwfM2pZHMvikEsmQpUdw2gCBEVfAb3DE8qziNhzDAdwr3dL44t34JfdYvXxi5GmpVmvYi5BVvBvBF6Dwwfg1rGUTTAKsiy5kxU7J3dxtFDDKYu8LtgdktMYQRZsGHttL") }}>
                Payment Failure
              </Button>
              <Button size='middle' color='default' onClick={() => { navigate("/payment/N86TFEiu32beDerdcwvVEVa9HrnU9h9iCRRzP9Rn8f2B2A69iHR9CpK5Dyw9fXAZ8VHkzWvNPg5VXdVkJfG3ffnNDc1apnaUUAJsHWVB6UY34NKU1r83f19SD6VNCdVckcU2A5CLGL") }}>
                Payment Cancel
              </Button>
            </Space>
          </Block>

          <Stack title="Single Stack" visible={visible1} setVisible={setVisible1} position="bottom">
            <h1>Stack 1</h1>
          </Stack>
          <Stack title="Stack By Stack 1" visible={stackStatus.stack1} setVisible={setVisible2} onBack={() => { navigate('/main/unitTest') }}>
            <h1>Stack 1</h1>
            <Button size='middle' color='danger' onClick={() => { setVisible3(true); }}>
              Stack 2
            </Button>
          </Stack>
          <Stack title="Stack By Stack 2" visible={stackStatus.stack2} setVisible={setVisible3}>
            <h1>Stack 2</h1>
          </Stack>

        </div>
      </div>
    </>
  )
}

export default Playground;