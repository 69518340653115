import React, { useEffect, useRef, useState } from "react";
import styles from "./modal.module.scss";
import { Modal } from "antd";

const TickLocalModal = (props) => {
  const {
    animationType = "none",
    visible = false,
    children,
    bgColor,
    showFooter,
  } = props;

  const [isVisible, setIsVisible] = useState(false);
  const __container = useRef(null);

  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        setIsVisible(false);
      }, 500);
    } else {
      setIsVisible(true);
    }
  }, [visible]);

  useEffect(() => {
    if (!__container) return;
    if (!visible) return;
    setTimeout(() => {
      __container?.current?.classList?.add(styles["slide-in"]);
    }, 1);
    return () => __container?.current?.classList?.remove(styles["slide-in"]);
  }, [__container, visible]);

  useEffect(() => {
    if (!__container) return;
    if (visible) return;
    setTimeout(() => {
      __container?.current?.classList?.add(styles["slide-out"]);
    }, 1);
    return () => __container?.current?.classList?.remove(styles["slide-out"]);
  }, [__container, visible]);

  return (
    <Modal
      wrapClassName={styles.tickGlobalModalContainer}
      open={isVisible}
      maskClosable={false}
      centered
      footer={null}
      closeIcon={null}
      className="localModal"
      transitionName=""
      maskTransitionName=""
    >
      <>
        <div
          ref={__container}
          className={styles.localModalContainer}
          style={{ background: bgColor ? bgColor : "#FFF", height: "100%" }}
        >
          {children}
        </div>
        {showFooter ? <div>{showFooter}</div> : null}
      </>
    </Modal>
  );
};

export default TickLocalModal;
