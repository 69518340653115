import ItemSet from "./ItemSet";
import ItemStyle from "./ItemStyle";

export default class Bag {
  constructor(itemSets) {
    this.itemSets = itemSets;
  }

  get(code) {
    return new ItemSet(this.itemSets.find(set => set.code === code))
  }

  /**
   * Remove set by set index
   * @param {int} index 
   * @returns true / false
   */
  remove(index) {
    if (index > this.itemSets.length - 1 || index < 0) return false;
    if (this.itemSets.length === 0) return true;
    this.itemSets.splice(index, 1);
    return true;
  }

  removeItem(sku, setIndex) {
    if (sku === null || sku === undefined) return false;
    if (typeof sku !== "string") return false;

    let deleteFromStyles = false;
    let deleteFromOptions = false;
    let set = this.itemSets[setIndex]

    //delete from styles
    for (let [key, value] of Object.entries(set.styles)) {
      const style = new ItemStyle(value);
      let result = style.remove(sku);
      if (result) {
        set.styles[key].items = result
        if (Object.keys(result).length === 0) {
          delete set.styles[key];
        }
        deleteFromStyles = true;
      }
    }

    //delete from options
    let style = sku.substring(0, 10);
    let size = sku.substring(10);
    for (let option of set.options) {
      for (let item of option.items) {
        if (item.style === style) {
          for (let [key, value] of Object.entries(item.selected.qty)) {
            if (key.startsWith(size)) { //TODO: can change to if (key === size) after having real data
              item.selected.qty[key] = 0;
            }
          }
          // if all qty size pair is 0, delete selected object from item
          if (Object.values(item.selected.qty).every(value => value === 0)) {
            delete item.selected;
          }
          deleteFromOptions = true;
        }
      }
    }
    this.itemSets[setIndex] = set;

    // if no style left in set, delete the whole set
    this.itemSets = this.itemSets.filter(set => Object.keys(set.styles).length !== 0);

    if (deleteFromStyles && deleteFromOptions) {
      return true;
    } else {
      return false;
    }
  }

  toArray(callback) {
    if (this.itemSets.length <= 0) return;

    let items = [];
    for (let set of this.itemSets) {
      items = items.concat(new ItemSet(set).toArray(callback))
    }
    return items;
  }
  
  toMap(callback) {
    if (this.itemSets.length <= 0) return;

    let map = {};
    for (let set of this.itemSets) {
      map = { ...map, ...new ItemSet(set).toMap(callback) }
    }
    return map;
  }

  total() {
    if (this.itemSets.length <= 0) return;

    let qty = 0, price = 0;
    for (let set of this.itemSets) {
      const setTotal = new ItemSet(set).total();
      if (setTotal) {
        qty += setTotal.qty;
        price += setTotal.price;
      }
    }
    return { qty, price }
  }

}