import package_json from "../../package.json";

export const APP = {
  env: "uat",
  auth: {
    accessToken: null,
    userId: null,
    isAuth: false,
    userName: null,
    userType: null,
  },
  version: package_json.version,
};

export const envConfig = {
  local: {
    PROTOCOL: "http://",
    HOST: "localhost:3000",
    API_PROTOCOL: "https://",
    API_HOST: "qruos-dev.kloveruniform.surpasstailor.com",
  },
  dev: {
    PROTOCOL: "https://",
    HOST: "app-dev.kloveruniform.surpasstailor.com",
    API_PROTOCOL: "https://",
    API_HOST: "qruos-dev.kloveruniform.surpasstailor.com",
  },
  uat: {
    PROTOCOL: "https://",
    HOST: "app-uat.kloveruniform.surpasstailor.com",
    API_PROTOCOL: "https://",
    API_HOST: "qruos-uat.kloveruniform.surpasstailor.com",
  },
  prod: {
    PROTOCOL: "https://",
    HOST: "app.kloveruniform.surpasstailor.com",
    API_PROTOCOL: "https://",
    API_HOST: "qruos.kloveruniform.surpasstailor.com",
  },
};

export const SUFFIX = "/qruos_api";

export const endpoint = {
  apiUrl: envConfig[APP.env].API_PROTOCOL + envConfig[APP.env].API_HOST + SUFFIX,
  baseUrl: envConfig[APP.env].PROTOCOL + envConfig[APP.env].HOST
};
