import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  status: { loading: true }
};

export const tickStackSlice = createSlice({
  name: 'tickStack',
  initialState: {
    status: { loading: true },
    stacks: [],
  },
  reducers: {
    setStackVisible: (state, newState) => {
      state.status = { ...state.status, [newState.payload.key]: newState.payload.value }
    },
    pop: (state, newState) => {
      const stack = state.stacks.pop();
      state.status = { ...state.status, [stack]: undefined }
    },
    push: (state, newState) => {
      state.stacks.push(newState.payload);
      state.status = { ...state.status, [newState.payload]: true }
    },
  },
});

export const { setStackVisible, pop, push } = tickStackSlice.actions;

export default tickStackSlice.reducer;
