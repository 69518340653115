import React from 'react';
import styles from './ArrowButton.module.scss'
import { RiArrowRightSLine } from '@remixicon/react';
import { Button } from 'antd-mobile';

const CustomArrowButton = ({ 
  children,
  textColor = "var(--adm-color-text-secondary)",
  lineColor = "var(--adm-color-border)",
  backgroundColor,
  onClick
}) => {
  return (
    <div 
      className={styles.customArrowButton} 
      // block 
      // shape='rectangular' 
      onClick={onClick} 
      style={{ backgroundColor, borderBottom: `1px ${lineColor} solid` }}
    >
      <div className={styles.arrowButtonContent}>
        <div className={styles.customElement}>
          { children }
        </div>
        <RiArrowRightSLine color={textColor} />
      </div>
    </div>
  )
}

export default CustomArrowButton