import React from 'react';
import styles from './UniformSizeItem.module.scss';
import { useDispatch } from 'react-redux';
import { Form, Stepper } from "antd-mobile";
import { useTranslation } from "react-i18next";
import useFormInstance from 'antd/es/form/hooks/useFormInstance';

const UniformSizeItem = ({ 
  size,
  price,
  outOfStock = false,
  maxQty,
  disabled,
  onChange
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.uniformSizeItem}>
        <div className={styles.left}>
          <div className={styles.itemName}>{size}</div>
        </div>
        <div className={styles.right}>
          <div className={styles.price}>HK${price}</div>
          <div className={styles.selector}>
            {
              outOfStock ?
              <div>{t("t_outOfStock")}</div> : 
              <Form.Item name={size}>
                <Stepper min={0} max={maxQty} disabled={disabled} onChange={onChange}/>
              </Form.Item>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default UniformSizeItem;