import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Input } from "antd-mobile";
import Stack from "../Stack";
import { useSelector } from "react-redux";

const ForgotPasswordStack = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm()
  const stackStatus = useSelector((state) => state.tickStack.status);

  const onFinish = () => {}

  return (
    <Stack
      title={t("t_forgotPassword")}
      visible={stackStatus.forgotPassword}
    >
      <div style={{width: "100%"}}>
        <Form
          form={form}
          onFinish={onFinish}
          footer={
            <>
              <Button block type='submit' color='primary' size='large'>
                {t('t_submit')}
              </Button>
            </>
          }
        >
          <Form.Item
            name='emailAddress'
            label={t("t_emailAddress")}
          >
            <Input clearable placeholder={`${t("t_pleaseEnter")}${t("t_emailAddress")}`} />
          </Form.Item>
        </Form>
      </div>
    </Stack>
  );
};

export default ForgotPasswordStack;
