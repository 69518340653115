import { Modal } from "antd-mobile";
import { Helper } from "./Helper";

class Response {

  constructor(res) {
    this.success = res.success;
    this.message = res.message;
    this.data = res.data;
  }

  handle(successCallBack, failCallBack) {
    if (this.success) {
      successCallBack(this.data);
    } else {
      if (failCallBack) failCallBack(this.data);
      else {
        Modal.clear();
        Modal.alert({
          bodyClassName: "loading",
          content: this.message[Helper.langShortForm("lower")] || this.message,
          // showCloseButton: true
        })
      }
    }
  }

}

export default Response;