import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { App } from "antd";
import BottomNavBar from "../../components/bottomNavBar/BottomNavBar";
import Playground from "../playground/Playground";
import ShoppingBag from "../shoppingBag/ShoppingBag";
import Uniform from "../uniform/Uniform";
import LoginStack from "../../components/stack/login/LoginStack";
import RegisterStack from "../../components/stack/register/RegisterStack";
import Order from "../order/Order";
import { Log } from "../../utils/Log";
import { useSelector } from "react-redux";

const TAG = "Main";

const Main = () => {

  const stackStatus = useSelector((state) => state.tickStack.status);

  useEffect(() => { 
    // You can add this logic to landing page or handle it in main page to valid the token from local storage
  }, []);

  // useEffect(() => {
  //   if (typeof page.title === "object") {
  //     setTitle(t(page.title.name, page.title.param))
  //   } else if (typeof page.title === "string") {
  //     setTitle(t(`t_${page.title}`));
  //   }
  // }, [page.title])

  return (
    <App>
      <div className="app">
        <Routes>  
          {/* <Route exact path="/" element={<Iframe />} /> */}
          <Route path="*" element={<Navigate to="/main/uniform" replace />}/>
          <Route exact path="/" element={<Playground />} />
          <Route exact path="/shoppingBag" element={<ShoppingBag/>} />
          <Route exact path="/uniform" element={<Uniform />} />
          <Route exact path="/order" element={<Order />} />
          <Route exact path="/unitTest" element={<Playground />} />
        </Routes>
        {/* <div className="top">
          <NavBar className="nav" backArrow={false} right={<Menu />}>
            { typeof page.title === "object" ? t(page.title.name, page.title.param) : t(`t_${page.title}`) }
          </NavBar>
        </div>
        <div className="body">
          <Routes>  
            <Route exact path="/" element={<Iframe />} />
            <Route path="*" element={<Navigate to="/main/uniform" replace />}/>
            <Route exact path="/" element={<Playground />} />
            <Route exact path="/shoppingBag" element={<ShoppingBag/>} />
            <Route exact path="/uniform" element={<Uniform />} />
            <Route exact path="/order" element={<div>Order</div>} />
            <Route exact path="/unitTest" element={<Playground />} />
          </Routes>
        </div> */}
        <div className="bottom">
          <BottomNavBar />
        </div>
      </div>
      
    </App>
  );
};

export default Main;
