import React, { useEffect, useState } from 'react'
import { Log } from '../../utils/Log';
import styles from './UniformSquareButton.module.scss'
import Logo from '../../assets/images/logo.png';
import { Ellipsis } from 'antd-mobile';
import { useTranslation } from 'react-i18next';

const TAG = "UniformSquareButton";

const UniformSquareButton = ({ 
  title,
  image,
  onClick
}) => {

  const { t } = useTranslation();
  const [src, setSrc] = useState(null);

  const checkImageValid = async () => {
    try {
      await fetch(image, { mode: 'no-cors' });
      setSrc(image);
    } catch (e) {
      setSrc(null)
    } 
  }
  
  useEffect(() => {
    if (image) checkImageValid(image)
  }, [])

  return (
    <>
      {
        src ? (
          <div className={styles.uniformSetChoice} onClick={onClick}>
            <div className={styles.content}>
              <img src={src} alt="" style={{ width: "100%", height: "80%" }} />
              <div className={styles.text}><Ellipsis content={title} /></div>
            </div>
          </div>
        ) : (
          <div className={styles.uniformSetChoice} onClick={onClick}>
            <div className={styles.content}>
              <div className={styles.noImageLogoContainer} >
                <img src={Logo} alt="" />
                <p>{t("t_noImageAvailable")}</p>
              </div>
              <div className={styles.text}><Ellipsis content={title} /></div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default UniformSquareButton;