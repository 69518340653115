import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from "./LogisticsInfo.module.scss"
import { TruckOutline } from 'antd-mobile-icons';
import ShopInformationStack from '../stack/shopInformation/ShopInformationStack';
import {
  RiShoppingBag4Line,
  RiTruckLine
} from "@remixicon/react";
import { useDispatch } from 'react-redux';
const LogisticsInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <>
      <div className={styles.container} onClick={() => { dispatch("shopInformation") }}>
        <div className={styles.deliveryMethod}>
          <RiShoppingBag4Line size={"17px"} />
          {/* <RiTruckLine size={"17px"} /> */}
          
          <div>已到分店</div>
        </div>
        {/* <div className={styles.trackingNumber}>快遞單號： SF202400000</div> */}
        <div className={styles.trackingNumber}>尖沙咀分店</div>

        {/* <div className={styles.copy}>| 複製</div> */}
      </div>
      <ShopInformationStack />
    </>
  )
}

export default LogisticsInfo