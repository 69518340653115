import Base from "./Base";
import Item, { CheckoutItem } from "./Item";
import ItemStyle from "./ItemStyle";

export default class ItemSet extends Base {
  constructor(set) {
    super(set)
    this.code = set.code;
    this.name_tc = set.name_tc;
    this.name_en = set.name_en;
    this.options = set.options;
    this.images = set.images;
    this.school_code = set.code.substring(0, 3);
    this.season_code = set.code.substring(3, 4);
    this.education_code = set.code.substring(4, 5);
    this.gender_code = set.code.substring(5, 6);
    this.uniform_type_code = set.code.substring(6, 7);

    //
    if (set.styles) {
      this.styles = {};
      for (let [key, value] of Object.entries(set.styles)) {
        this.styles[key] = value
      }
    }
  }

  get(styleCode) {
    if (!this.styles[styleCode]) return null;
    return new ItemStyle(this.styles[styleCode]);    
  }

  toMap(callback) {
    if (Object.keys(this.styles).length <= 0) return [];

    let map = {};
    for (let value of Object.values(this.styles)) {
      const itemStyle = new ItemStyle(value);
      for (let value of Object.values(itemStyle.items)) {
        map[value.sku] = new CheckoutItem(value)
      }
    }
    return map;
  }

  toArray(callback) {
    if (Object.keys(this.styles).length <= 0) return [];

    let items = [];
    for (let value of Object.values(this.styles)) {
      const itemStyle = new ItemStyle(value);
      for (let value of Object.values(itemStyle.items)) {
        const item = new Item(value, itemStyle);
        if (callback) callback(item)
        items.push(item)
      }
    }
    return items
  }

  total() {
    if (Object.keys(this.styles).length <= 0) return;

    let qty = 0, price = 0;
    for (let value of Object.values(this.styles)) {
      const styleTotal = new ItemStyle(value).total();
      if (styleTotal) {
        qty += styleTotal.qty || 0;
        price += styleTotal.price || 0;
      }
    }
    return { qty, price }
  }
}