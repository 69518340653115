import React, { useState, useEffect } from 'react';
import styles from './OrderItem.module.scss'
import { Ellipsis } from 'antd-mobile';
import { Helper } from '../../utils/Helper';

const TAG = "OrderItem";

const OrderItem = ({
  itemData
}) => {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(itemData);
  }, [itemData])

  return (
    <>
      <div className={styles.cardItemContainer}>
        <img src={data.item_images?.[0]} alt="uniform-image" className={styles.leftImage} />
        <div className={styles.middleText}>
          <div className={styles.upperInfo}>
            <Ellipsis content={data[Helper.toLangKey("name")] ? data[Helper.toLangKey("name")] : ''} />
          </div>
          <div className={styles.middleInfo}>
            {`${data[Helper.toLangKey("color")]} ; ${data[Helper.toLangKey("size")]}`}
          </div>
        </div>
        <div className={styles.rightPrice}>
          <div className={styles.price}>
            <span>$</span>
            <span>{parseInt(data.amount) ? parseInt(data.amount) * data.qty : 0}</span>
          </div>
          <div className={styles.qty}>{`x${data.qty}`}</div>
        </div>
      </div>
    </>
  )
}

export default OrderItem