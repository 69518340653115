import { NavBar, Popup } from 'antd-mobile'
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react'
import styles from "./Stack.module.scss"
import { Log } from '../../utils/Log';
import { pop } from '../../redux/tickStackSlice';

const TAG = "Stack"

const Stack = ({
  height,
  width,
  visible,
  title,
  children,
  position = "right",
  right = null,
  onBack,
}) => {

  const dispatch = useDispatch();

  const containerClass = [styles.stackContainer];
  const maskClass = [styles.mask];
  if (visible) {
    containerClass.push(styles.show)
    maskClass.push(styles.show)
  }

  useEffect(() => {
    if (visible) {
      window.history.pushState(null, null, document.URL);
    }
    window.onpopstate = (e) => {
      dispatch(pop())
    };

    return () => {
      window.onpopstate = null;
    };
  }, [visible]);


  return (
    <>
      <div className={`${containerClass.join(" ")}`}>
        <div className={styles.stackBody}>
          <div className="app">
            <div className="top">
              <NavBar
                className="nav"
                onBack={() => {
                  if (visible) dispatch(pop())
                  if (onBack) onBack();
                }}
                right={right}
              >
                {title}
              </NavBar>
            </div>
            <div className="body">
              {children}
            </div>
          </div>
        </div>
      </div>
      <div className={`${maskClass.join(" ")}`} />
    </>
  )
}

export default Stack