import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  show: false,
  params: {
    key: "none",
    msg: "default msg",
    btnText: "",
    showCloseBtn: false,
    callback: null,
  },
};

export const tickModalSlice = createSlice({
  name: "tickModal",
  initialState: {
    show: false,
    params: {
      key: "none",
      msg: "default msg",
      btnText: "",
      showCloseBtn: false,
      callback: null,
    },
  },
  reducers: {
    showModal: (state, newState) => {
      state.show = true;
      state.params = newState.payload;
    },
    closeModal: () => initialState,
  },
});

export const { showModal, closeModal } = tickModalSlice.actions;

export default tickModalSlice.reducer;
