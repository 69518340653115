import React, { useEffect, useState } from 'react'
import Stack from '../Stack';
import { useTranslation } from 'react-i18next';
import styles from "./UniformFilterStack.module.scss";
import Block from '../../block/Block';
import { CheckList, Selector } from 'antd-mobile';
import { uniformSeasonOptions } from '../../../data/uniformSeasonOptions';
import { useDispatch, useSelector } from 'react-redux';
import { setUniformPageParam } from '../../../redux/tickPageSlice';
import { Log } from '../../../utils/Log';
import { Helper } from '../../../utils/Helper';
import { pop } from '../../../redux/tickStackSlice';

const TAG = "UniformFilterStack";

const UniformFilterStack = ({
  visible,
  setVisible,
}) => {

  const { t } = useTranslation();

  const uniformPageState = useSelector((state) => state.tickPage.params.uniformPage)
  
  const [gender, setGender] = useState();
  const [season, setSeason] = useState();

  const [initialState, setInitialState] = useState();
  
  const genderOptions = Helper.getGenderOptions();
  const seasonOptions = Helper.getSeasonOptions();

  const dispatch = useDispatch();

  const right = (
    <p
      className={styles.completeButton}
      onClick={() => {
        dispatch(setUniformPageParam({ gender, season }));
        dispatch(pop())
        // if (visible) dispatch(pop())
      }}
    >
      {t("t_complete")}
    </p>
  )

  const onBack = () => {
    setGender(initialState.gender)
    setSeason(initialState.season)
  }

  useEffect(() => {
    setGender(uniformPageState.gender)
    setSeason(uniformPageState.season)

    setInitialState({ gender, season });
  }, [])

  useEffect(() => {
    if (!uniformPageState) return;
    Log(TAG, uniformPageState)
    setGender(uniformPageState.gender)
    setSeason(uniformPageState.season)
  }, [uniformPageState])

  return (
    <Stack
      title={t("t_chooseUniform")}
      visible={visible}
      setVisible={setVisible}
      right={right}
      onBack={onBack}
    >
      <div className={styles.uniformFilterContainer}>
        <Block title={t("t_studentGender")}>
          <Selector
            className="genderSelector"
            columns={genderOptions.length}
            options={genderOptions}
            onChange={(val) => { setGender(val) }}
            defaultValue={uniformPageState?.gender || []}
            value={gender}
            multiple
          />
        </Block>
        <Block title={t("t_uniformSeason")} noPadding={true}>
          <CheckList multiple value={season} onChange={(val) => { setSeason(val) }}>
            {
              seasonOptions.map(season => 
                <CheckList.Item key={season.value} value={season.value}>{season.label}</CheckList.Item>
              )
            }
          </CheckList>
        </Block>
      </div>
    </Stack>
  )
}

export default UniformFilterStack;