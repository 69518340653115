import { message } from "antd";
import axios from "axios";
import qs from "qs";

// 创建axios实例
const axiosInstance = axios.create({
  baseURL: "https://663300def7d50bbd9b47dea7.mockapi.io/klapp/api",
  timeout: 60000 * 10,
  headers: { "Content-Type": "application/json;charset=UTF-8" },
  withCredentials: false,
});
axiosInstance.defaults.timeout = 60000 * 10;

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    switch (error.response.status) {
      case 400:
        // Message.error(error.response.data.message)
        break;
      case 401:
        // 登录过期
        localStorage.clear();
        // window.location.href = "./login";
        break;
      case 403:
        // 无权限
        // Message.error("no permission")
        break;
      case 422:
        // Message.error(error.response.data.message)
        break;
      case 500:
        // 服务器错误处理
        // Message.error("Internal Server Error")
        break;
      default:
      // Message.error('Network Error')
    }
  }
  return Promise.reject(error);
};

const failHandler = (error) => {
  if (typeof error === "string") {
    message.error(error);
  }
};

// 请求拦截处理器
axiosInstance.interceptors.request.use((config) => {
  // qs是axios自带的序列化参数方式
  if (
    config.headers &&
    config.headers["Content-Type"] &&
    config.headers["Content-Type"].includes("application/x-www-form-urlencoded")
  ) {
    config.params = qs.stringify(config.params);
  }
  return config;
}, errorHandler);

// 响应拦截处理器
axiosInstance.interceptors.response.use((response) => {
  return response;
}, errorHandler);

function request(config) {
  return new Promise((resolve, reject) => {
    axiosInstance
      .request(config)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function post(url, data, config) {
  return new Promise((resolve, reject) => {
    request({
      url: url,
      method: "POST",
      data: data,
      ...config,
    })
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          reject("error");
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function get(url, params, config) {
  const tempConfig = { ...config };
  return new Promise((resolve, reject) => {
    request({
      url: url,
      method: "GET",
      params,
      ...tempConfig,
    })
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          reject("error");
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default {
  post,
  get,
};
