import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavBar, Collapse } from "antd-mobile";
import { App } from "antd"
import Menu from "../../components/menu/Menu";
import { useNavigate } from "react-router-dom";
import styles from "./CustomerService.module.scss";
import { CustomerServiceService } from "../../service/apis/CustomerServiceService"
import { Helper } from "../../utils/Helper";

const CustomerService = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const nameLang = Helper.toLangKey("name");
  const contentLang = Helper.toLangKey("content");
  const [frequentlyAskedQuestions, setFrequentlyAskedQuestions] = useState([])

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const res = await CustomerServiceService.get()
    if (res.success) {
      setFrequentlyAskedQuestions(res.data)
    }
  }

  return (
    <App>
      <NavBar className="nav" backArrow onBack={() => navigate(-1)} right={<Menu />}>
        {t("t_customerService")}
      </NavBar>
      <div className={styles.titleText}>{t("t_frequentlyAskedQuestions")}</div>
      {
        frequentlyAskedQuestions.length > 0 ?
          <Collapse className={styles.collapseContainer}>
            {
              frequentlyAskedQuestions.map((item, index) =>
                <Collapse.Panel key={index} title={item[nameLang]}>
                  <div dangerouslySetInnerHTML={{ __html: item[contentLang] }} />
                </Collapse.Panel>
              )
            }
          </Collapse>
          : null
      }
    </App>
  )
}

export default CustomerService;