import { Log } from "../Log";
import Item from "./Item";

const TAG = "ItemStyle";

export default class ItemStyle {
  constructor(style) {
    this.gender = style.gender;
    this.type = style.type;
    this.name_tc = style.name_tc;
    this.name_en = style.name_en;
    this.remark_tc = style.remark_tc;
    this.remark_en = style.remark_en;
    this.item_images = style.item_images;
    this.care_label_images = style.care_label_images;
    this.size_spec_images = style.size_spec_images;

    if (style.items) {
      this.items = {};
      for (let [key, value] of Object.entries(style.items)) {
        this.items[key] = value
      }
    }
  }

  get(sku) {
    if (!this.items[sku]) return null;
    return new Item(this.items[sku])
  }

  remove(sku) {
    if (!this.items[sku]) return null;
    delete this.items[sku];
    return this.items
  }

  total() {
    if (Object.keys(this.items).length <= 0) return;

    let qty = 0, price = 0;
    for (let value of Object.values(this.items)) {
      const item = new Item(value, this);
      qty += item.qty;
      price += (item.qty * item.price);
    }
    return { qty, price }
  }

}