import React from 'react'
import styles from './Block.module.scss'

const Block = ({ 
  title,
  noPadding = false,
  children 
}) => {
  return (
    <>
      <div className={styles.blockContainer}>
        <div className={styles.blockTitle}>{ title }</div>
        <div className={`${styles.blockComponent} ${ noPadding && styles.noPadding }`}>
          { children }
        </div>
      </div>
    </>
  )
}

export default Block