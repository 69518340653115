import React from "react";
import styles from "./AuthContent.module.scss";
import { App } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavBar } from "antd-mobile";

const AuthContent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onBack = () => {
    navigate("/unitTest");
  };

  return (
    <App>
      <div className="app">
        <div className="top">
          <NavBar className="nav" onBack={onBack}>
            {t("t_title")}
          </NavBar>
        </div>
        <div className={styles.container}>This is Auth Content page</div>
      </div>
    </App>
  );
};

export default AuthContent;
