import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  set: {}
};

export const tickTempUniformSetSlice = createSlice({
  name: "tickTempUniformSet",
  initialState: {
    set: {}
  },
  reducers: {
    setData: (state, newState) => {
      state.set = newState.payload;
    },
    updateSelectedData: (state, newState) => {
      const updatedOptions = state.set.options.map((option) => {
        const updatedItems = option.items.map((item) => {
          if (item.style === newState.payload.style) {
            return {
              ...item,
              selected: newState.payload.selected,
            };
          }
          return item;
        });
        return {
          ...option,
          items: updatedItems,
        };
      });
    
      return {
        ...state,
        set: {
          ...state.set,
          options: updatedOptions,
        },
      };
    },
    removeSelectedData: (state, newState) => {
      const updatedOptions = state.set.options.map((option) => {
        const updatedItems = option.items.map((item) => {
          if (item.style === newState.payload.style) {
            const { selected, ...rest } = item;
            return rest;  // create a new object without the 'selected' key
          }
          return item;
        });
        return {
          ...option,
          items: updatedItems,
        };
      });
    
      return {
        ...state,
        set: {
          ...state.set,
          options: updatedOptions,
        },
      };
    },
    reset: () => initialState,
  },
});

export const { 
  setData,
  updateSelectedData,
  removeSelectedData,
  reset 
} = tickTempUniformSetSlice.actions;

export default tickTempUniformSetSlice.reducer;
