import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Stack from "../Stack";
import styles from './ChooseUniformStack.module.scss'
import ChooseUniformItem from "../../chooseUniformItem/ChooseUniformItem";
import { useSelector } from 'react-redux';
import { Helper } from '../../../utils/Helper';
import { Button, Toast } from 'antd-mobile';
import ChooseStyleStack from "../chooseStyle/ChooseStyleStack";
import { useDispatch } from 'react-redux';
import { LocalStorage } from "../../../utils/LocalStorage";
import { Log } from "../../../utils/Log";
import { pop, push } from "../../../redux/tickStackSlice";
import { useLocation } from "react-router";

const TAG = "ChooseUniformStack";

const ChooseUniformStack = () => {
  const { t } = useTranslation();

  const tempUniformSetState = useSelector((state) => state.tickTempUniformSet.set);
  const stackStatus = useSelector((state) => state.tickStack.status);
  const stacks = useSelector((state) => state.tickStack.stacks);
  // const [chooseStyleVisible, setChooseStyleVisible] = useState(false);
  const [chooseStyleStackData, setChooseStyleStackData] = useState({});

  const dispatch = useDispatch();
  const location = useLocation();

  const totalPrice = () => {
    let totalPriceCount = 0;
    if (Object.keys(tempUniformSetState).length === 0) return 0;
    for (let option of tempUniformSetState.options) {
      for (let item of option.items) {
        let itemTotal = 0;
        if (item.hasOwnProperty('selected')) {
          let qtyObj = item.selected.qty;
          let sizeBreakdownObj = item.selected.sizeBreakdown;
          for (let size in qtyObj) {
            let qty = qtyObj[size];
            if (qty > 0) {
              for (let i of sizeBreakdownObj) {
                if (size === i.size.name_tc) {
                  itemTotal += (Number(i.price) * qty);
                }
              }
            }
          }
        }
        totalPriceCount += itemTotal;
      }
    }
    return Helper.formatNumberWithCommas(totalPriceCount);
  }

  const formattedSizeString = (obj) => {
    return Object.entries(obj)
    .filter(([key, value]) => value > 0 && key !== 'isNoNeed')
    .map(([key, value]) => `${key} x ${value}`)
    .join(', ');
  }

  useEffect(() => {
    // if (!visible) {
    //   dispatch(reset());
    // }

    if (stackStatus.chooseUniform) {
      // action for user clicking shopping bag card item
      if (tempUniformSetState.hasOwnProperty('goToOptionIndex') && tempUniformSetState.goToOptionIndex >= 0) {
        // setChooseStyleVisible(true);
        dispatch(push("chooseUniformStyle"))
        setChooseStyleStackData(tempUniformSetState.options[tempUniformSetState.goToOptionIndex]);
      }
    }
  }, [stackStatus.chooseUniform])

  useEffect(() => {
    if (!stackStatus.chooseUniformStyle) {
      // action for user clicking shopping bag card item, auto save and close chooseUniformStack
      if (tempUniformSetState.hasOwnProperty('goToOptionIndex') && tempUniformSetState.goToOptionIndex >= 0) {
        onSave();
      }
    }
  }, [stackStatus.chooseUniformStyle])

  const onAddToShoppingBagClick = () => {
    // setVisible(false);
    let hasSelectedItem = onSave();
    Log(TAG, location)
    if (hasSelectedItem && location.pathname === "/main/uniform") {
      const recommendations = Helper.getRecommendations();
      Toast.show({
        icon: 'success',
        content: t("t_addedToShoppingBag"),
        position: 'center',
        duration: 1000,
        afterClose: () => {
          if (recommendations.length > 0) dispatch(push("recommendation"))
        }
      })
    }
  }

  const onSave = () => {
    if (Helper.peek(stacks) === "chooseUniform") dispatch(pop());

    // create styles for shopping bag display
    let hasSelectedItem = false;
    let styles = {};
    for (let option of tempUniformSetState.options) {
      for (let item of option.items) {
        if (item.hasOwnProperty('selected')) {
          Log(TAG, item)
          hasSelectedItem = true;

          let items = {};
          for (let size in item.selected.qty) {
            let qty = item.selected.qty[size];
            if (qty > 0) {
              let sizeDetails = item.selected.sizeBreakdown.find(i => i.size.name_tc === size);
              items[`${sizeDetails.sku}`] = {
                sku: sizeDetails.sku,
                size: sizeDetails.size,
                price: sizeDetails.price,
                qty: qty
              }
            }
          }
          if (Object.keys(items).length > 0) {
            styles[`${item.style}`] = {
              style: item.style,
              name_tc: item.name_tc,
              name_en: item.name_en,
              item_images: item.selected.photo,
              items
            }
          }
        }
      }
    }
    
    let shoppingBagSetObject = {
      ...tempUniformSetState,
      styles
    }
    let shoppingBagLocalStorage = LocalStorage.get("shoppingBag").toObject()

    if (Object.keys(styles).length > 0) {
      // item selected in the set
      if (shoppingBagSetObject.hasOwnProperty('editIndex')) {
        // replace set in shopping bag with index
        let index = shoppingBagSetObject.editIndex
        shoppingBagLocalStorage[index] = shoppingBagSetObject;
        LocalStorage.set("shoppingBag", JSON.stringify(shoppingBagLocalStorage));
      } else {
        // add new set to shopping bag
        shoppingBagLocalStorage.push(shoppingBagSetObject);
        LocalStorage.set("shoppingBag", JSON.stringify(shoppingBagLocalStorage));
      }

    } else {
      // no item selected in the set (delete by index)
      if (shoppingBagSetObject.hasOwnProperty('editIndex')) {
        let index = shoppingBagSetObject.editIndex
        shoppingBagLocalStorage.splice(index, 1);
        LocalStorage.set("shoppingBag", JSON.stringify(shoppingBagLocalStorage));
      }
    }
    return hasSelectedItem;
  }
  
  return (
    <Stack
      title={tempUniformSetState[Helper.toLangKey("name")]}
      // setVisible={setVisible}
      visible={stackStatus.chooseUniform}
    >
      <div style={{ width: "100%" }}>
        <img src={tempUniformSetState.images} alt="uniform-image" className={styles.mainUniformPic} />
        {
          tempUniformSetState?.options?.map((item, index) => {
            const selectedItems = item.items.filter(item => item.selected);
            if (selectedItems.length === 0) {
              // no item selected
              return (
                <ChooseUniformItem 
                  key={index} 
                  chooseText={item[Helper.toLangKey("name")]}
                  onClick={() => {
                    dispatch(push("chooseUniformStyle"))
                    // setChooseStyleVisible(true);
                    setChooseStyleStackData(item);
                  }}
                  required={item.required}
                  image={item.image ? item.image : item.items[0].item_images}
                />
              )
            } else {
              // map all selected items
              return selectedItems.map((selectedItem, selectedItemIndex) => {
                return (
                  <ChooseUniformItem 
                    key={`SelectedItem_${selectedItem.style}`} 
                    title={selectedItem[Helper.toLangKey("name")]} 
                    onClick={() => {
                      dispatch(push("chooseUniformStyle"))
                      // setChooseStyleVisible(true);
                      setChooseStyleStackData(item);
                    }}
                    image={selectedItem.selected.photo}
                    size={formattedSizeString(selectedItem.selected.qty)}
                />
                )
              })
            }
          })
        }
        <div className={styles.totalSum}>
          <div>{t("t_shoppingBagTotal")}</div>
          <div style={{ color: "var(--adm-color-primary)"}}>HK${totalPrice()}</div>
        </div>
        <div style={{ margin: "0 10px", paddingBottom: "10px" }}>
          <Button block type='submit' color='primary' size='large' onClick={onAddToShoppingBagClick}>
            { tempUniformSetState.hasOwnProperty('editIndex') ? t('t_updateShoppingBag') : t('t_addToShoppingBag') }
          </Button>
        </div>
      </div>
      <ChooseStyleStack 
        visible={stackStatus.chooseUniformStyle} 
        // setVisible={setChooseStyleVisible} 
        chooseStyleStackData={chooseStyleStackData}
      />
    </Stack>
  );
};

export default ChooseUniformStack;