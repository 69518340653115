import React, { useEffect, useState } from 'react';
import { NavBar, CapsuleTabs, Card } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from "./Order.module.scss"
import OrderItem from '../../components/orderItem/OrderItem';
import LogisticsInfo from '../../components/logisticsInfo.jsx/LogisticsInfo';
import { TransactionService } from '../../service/apis/TransactionService';
import { MasterDataService } from '../../service/apis/MasterDataService';
import { Helper } from '../../utils/Helper';
import Response from '../../utils/Response';
import { Log } from '../../utils/Log';
import { orderStatus } from '../../data/orderStatus';
import { LocalStorage } from '../../utils/LocalStorage';
import { useDispatch } from 'react-redux';
import { push, setStackVisible } from '../../redux/tickStackSlice';

const TAG = "Order";

const Order = () => {
  const [orders, setOrders] = useState([]);
  const [waitForPickupOrders, setWaitForPickupOrders] = useState([]);
  const [paySuccessOrders, setPaySuccessOrders] = useState([]);
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const onTabChange = (val) => {
  }

  const fetchOrders = async () => {
    const masterDataRes = await MasterDataService.get();
    let masterData;
    if (masterDataRes.success) masterData = masterDataRes.data;

    const res = await await TransactionService.get();
    new Response(res).handle((data) => {
      Log(TAG, data)
      data = Helper.mapTransWithMasterData(data, masterData)
      setOrders(data);
      setPaySuccessOrders(data.filter(item => item.status === "PAY_SUCCESS"))
      setWaitForPickupOrders(data.filter(item => item.status === "WAIT_FOR_PICK_UP"))
    }, () => {
      // fail
    })
  }

  useEffect(() => {
    fetchOrders();
  }, [])

  return (
    <>
      <div className="top">
        <NavBar className="nav" onBack={() => navigate("/main/uniform")}>
          {t("t_order")}
        </NavBar>
      </div>
      <div className="body">
        <div className={styles.container}>
          <div className={styles.tab}>
            <CapsuleTabs onChange={onTabChange}>
              <CapsuleTabs.Tab title={t("t_total")} key='fruits' >
                {
                  orders.map((order, index) => {
                    return (
                      <Card 
                        className="card"
                        key={order.id}
                        title={`${t("t_orderNum")}: ${order.id}`}
                        extra={
                          <div className={styles.orderStatus}>{orderStatus[`${order.status}`]?.label}</div>
                        }
                      >
                        {
                          order.lines.map((item, index) => {
                            return (
                              <OrderItem 
                                itemData={item} 
                                key={`${item.tran_id}_${item.id}`} 
                              />
                            )
                          })
                        }
                        <div className={styles.logisticsInfoContainer}>
                          {
                            order.status === "WAIT_FOR_PICK_UP" && 
                            <LogisticsInfo />
                          }
                        </div>
                      </Card>
                    )
                  })
                }
              </CapsuleTabs.Tab>
              <CapsuleTabs.Tab title={t("t_readyForPickup")} key='vegetables' >
              {
                  waitForPickupOrders.map((order, index) => {
                    return (
                      <Card 
                        className="card"
                        key={order.id}
                        title={`${t("t_orderNum")}: ${order.id}`}
                        extra={
                          <div className={styles.orderStatus}>{orderStatus[`${order.status}`]?.label}</div>
                        }
                      >
                        {
                          order.lines.map((item, index) => {
                            return (
                              <OrderItem 
                                itemData={item} 
                                key={`${item.tran_id}_${item.id}`} 
                              />
                            )
                          })
                        }
                        <div className={styles.logisticsInfoContainer}>
                          <LogisticsInfo />
                        </div>
                      </Card>
                    )
                  })
                }
              </CapsuleTabs.Tab>
              <CapsuleTabs.Tab title={t("t_waitForDelivery")} key='animals' >
              {
                  paySuccessOrders.map((order, index) => {
                    return (
                      <Card 
                        className="card"
                        key={order.id}
                        title={`${t("t_orderNum")}: ${order.id}`}
                        extra={
                          <div className={styles.orderStatus}>{orderStatus[`${order.status}`]?.label}</div>
                        }
                      >
                        {
                          order.lines.map((item, index) => {
                            return (
                              <OrderItem 
                                itemData={item} 
                                key={`${item.tran_id}_${item.id}`} 
                              />
                            )
                          })
                        }
                      </Card>
                    )
                  })
                }
              </CapsuleTabs.Tab>
            </CapsuleTabs>
          </div>
        </div>
      </div>
    </>
  )
}

export default Order;