import React, { useState } from 'react';
import styles from './CheckoutItem.module.scss'
import { Ellipsis } from 'antd-mobile';
import { Helper } from '../../utils/Helper';
import Item from '../../utils/entity/Item';
import { Log } from '../../utils/Log';

const TAG = "CheckoutItem";

const CheckoutItem = ({
  data
}) => {
  const [item] = useState(new Item(data));
  return (
    <>
      <div className={styles.cardItemContainer}>
        <img src={item.style.item_images[0]} alt="uniform-image" className={styles.leftImage} />
        <div className={styles.middleText}>
          <div className={styles.upperInfo}>
            <Ellipsis content={item.style[Helper.toLangKey("name")]} />
          </div>
          <div className={styles.middleInfo}>
            { `${item.color}; ${item.size[Helper.toLangKey("name")]}` }
          </div>
        </div>
        <div className={styles.rightPrice}>
          <div className={styles.price}>
            <span>$</span>
            <span>{item.price * item.qty}</span>
          </div>
          <div className={styles.qty}>{`x${item.qty}`}</div>
        </div>
      </div>
    </>
  )
}

export default CheckoutItem