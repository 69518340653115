import React from "react";
import { Image } from "antd-mobile";
import styles from "./PaymentMethodOption.module.scss";
import PayPalImg from "../../assets/images/paypal.png"
import VISAImg from "../../assets/images/visa.png"
import MasterImg from "../../assets/images/master.png"
import AlipayImg from "../../assets/images/alipay.png"
import WeChatPayImg from "../../assets/images/weChatPay.png"
import PayMeImg from "../../assets/images/payme.png"

const PaymentMethodOption = ({
  gateway
}) => {
  return (
    <>
      {
        gateway === "paypal" ?
          <div className={styles.imagesContainer}>
            <div className={styles.img}>
              <Image src={PayPalImg} />
            </div>
            <div className={styles.img}>
              <Image src={VISAImg} />
            </div>
            <div className={styles.img}>
              <Image src={MasterImg} />
            </div>
          </div>
        : gateway === "recon" ?
            <div className={styles.imagesContainer}>
              <div className={styles.img}>
                <Image src={AlipayImg} />
              </div>
              <div className={styles.img}>
                <Image src={WeChatPayImg} />
              </div>
              <div className={styles.img}>
                <Image src={PayMeImg} />
              </div>
            </div>
        : null
      }
    </>
  );
};

export default PaymentMethodOption;