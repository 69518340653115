export class Logger {

  static map = {}; // { logTitle, value }
  static mode = "on";

  static show(title, log) {
    if (this.mode === "off") return;
    if (this.map[title] && this.map[title] === "enabled") {
      console.log(`[${title}] -`, log);
    } else if (!this.map[title]) {
      console.log(`[${title}] -`, log);
      this.enable(title)
    } 
  }

  static enable(title) {
    this.map[title] = "enabled";
  }

  static disable(title) {
    this.map[title] = "disabled";
  }

}

export const Log = (title, log) => {
  Logger.show(title, log);
}