import React, { useEffect, useState } from "react";
import { App, Button, Row } from "antd";
import styles from "./UnitTest.module.scss";
import { useNavigate } from "react-router-dom";
import { APP, endpoint } from "../../configs/env";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { showModal } from "../../redux/tickModalSlice";
import { useDispatch } from "react-redux";
import { AuthService, postLogin } from "../../service/apis/AuthService";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import TickLocalModal from "../../components/modals/TickLocalModal";
import { NavBar } from "antd-mobile";

const UnitTest = () => {
  const [language, setLanguage] = useState("en");
  const [isLocalModalVisible, setIsLocalModalVisible] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { setValue } = useLocalStorage("token", null);

  i18n.on("languageChanged", (lang) => setLanguage(lang));

  useEffect(() => {
    if (APP.env === "prod") {
      navigate("/");
    }
  }, []);

  const handleNavigate = (path) => {
    navigate(`/${path}`);
  };

  const changeLocale = (lang) => {
    if (language !== lang) {
      i18n.changeLanguage(lang);
    }
  };

  const handleLogin = async () => {
    // setValue("TOKEN"); // Set the token to the localStorage
    // This just testing the axios
    try {
      const resp = await AuthService.postLogin({
        username: "admin",
        password: "admin",
        rememberMe: false,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleLogout = () => {
    setValue(null);
  };

  const handleLocalModalVisible = () => {
    setIsLocalModalVisible(!isLocalModalVisible);
  };

  return (
    <>
      <App>
        <div className={styles.container}>
          <div className={styles.title}>
            <h1>Unit Test</h1>
          </div>
          <div className={styles.content}>
            <h3>{`Version: ${APP.version}`}</h3>
            <h3>{`Env: ${APP.env}`}</h3>
            <h3>{`Endpoint: ${endpoint.apiUrl}`}</h3>
            <h3>{`Current Language: ${language.toUpperCase()}, Example: ${t(
              "t_hello"
            )}`}</h3>
            <Row justify={"space-evenly"}>
              <div className={styles.btn}>
                <Button type="primary" onClick={() => handleNavigate("main")}>
                  Navigate to Main
                </Button>
              </div>

              <div className={styles.btn}>
                <Button
                  type="primary"
                  onClick={() => handleNavigate("main/home")}
                >
                  {`Navigate to Main(home)`}
                </Button>
              </div>

              <div className={styles.btn}>
                <Button
                  type="primary"
                  onClick={() => handleNavigate("main/toDo")}
                >
                  {`Navigate to Main(toDo)`}
                </Button>
              </div>

              <div className={styles.btn}>
                <Button
                  type="primary"
                  onClick={() => handleNavigate("main/message")}
                >
                  {`Navigate to Main(message)`}
                </Button>
              </div>

              <div className={styles.btn}>
                <Button
                  type="primary"
                  onClick={() => handleNavigate("main/about")}
                >
                  {`Navigate to Main(about)`}
                </Button>
              </div>

              <div className={styles.btn}>
                <Button type="primary" onClick={() => handleNavigate("login")}>
                  Navigate to Login
                </Button>
              </div>

              <div className={styles.btn}>
                <Button
                  type="primary"
                  onClick={() => handleNavigate("needAuthContent")}
                >
                  Navigate to Auth Content
                </Button>
              </div>

              <div className={styles.btn}>
                <Button
                  type="primary"
                  onClick={() => {
                    if (language === "en") {
                      changeLocale("tc_hk");
                    } else {
                      changeLocale("en");
                    }
                  }}
                >
                  Change Language
                </Button>
              </div>

              <div className={styles.btn}>
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch(
                      showModal({
                        key: "success",
                        msg: "Success",
                      })
                    );
                  }}
                >
                  Show Success Modal
                </Button>
              </div>

              <div className={styles.btn}>
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch(
                      showModal({
                        key: "loading",
                      })
                    );
                  }}
                >
                  Show Loading Modal
                </Button>
              </div>

              <div className={styles.btn}>
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch(
                      showModal({
                        key: "warning",
                        msg: [{ msg: "Waring" }],
                        btnText: "confirm",
                      })
                    );
                  }}
                >
                  Show Warning Modal
                </Button>
              </div>

              <div className={styles.btn}>
                <Button type="primary" onClick={handleLogin}>
                  Login
                </Button>
              </div>

              <div className={styles.btn}>
                <Button type="primary" onClick={handleLogout}>
                  Logout
                </Button>
              </div>

              <div className={styles.btn}>
                <Button type="primary" onClick={handleLocalModalVisible}>
                  Open Local Modal
                </Button>
              </div>
            </Row>
          </div>
        </div>
      </App>
      {/* {isLocalModalVisible ? ( */}
      <TickLocalModal visible={isLocalModalVisible}>
        <div style={{ width: "100%", height: "100vh" }}>
          <NavBar onBack={() => setIsLocalModalVisible(!isLocalModalVisible)}>
            {t("t_title")}
          </NavBar>
          <div style={{ padding: "16px" }}>This is local modal</div>
        </div>
      </TickLocalModal>
      {/* ) : null} */}
    </>
  );
};

export default UnitTest;
