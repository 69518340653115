import TickGlobalModal from "./TickGlobalModal";
import styles from "./modal.module.scss";
import loadingIcon from "../../assets/icons/modalLoading.gif";
import { SpinLoading } from "antd-mobile";

const TickLoadingModal = () => {
  return (
    <TickGlobalModal>
      {/* <div className={styles.loadingContainer}>
        <img src={loadingIcon} alt="loading..." width={100} />
      </div> */}
      <SpinLoading color='primary' />
    </TickGlobalModal>
  );
};

export default TickLoadingModal;
