import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { List, CheckList } from "antd-mobile";
import Stack from "../Stack";
import PaymentMethodOption from "../../paymentMethodOption/PaymentMethodOption";
import { useDispatch, useSelector } from "react-redux";
import { Helper } from "../../../utils/Helper";
import { pop } from "../../../redux/tickStackSlice";

const PaymentMethodStack = ({
  setPaymentGateway
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stackState = useSelector((state) => state.tickStack);

  return (
    <>
      <Stack
        title={`${t("t_pleaseSelect")}${t("t_paymentMethod")}`}
        visible={stackState.status.paymentMethod}
      >
        <div style={{ width: "100%" }}>
          <CheckList
            activeIcon={null}
            onChange={(val) => {
              setPaymentGateway(val[0])
              if (Helper.peek(stackState.stacks) === "paymentMethod") dispatch(pop());
            }}
          >
            <List header={t("t_creditCard")}>
              <CheckList.Item value={"paypal"}>
                <PaymentMethodOption gateway={"paypal"} />
              </CheckList.Item>
            </List>
            <List header={t("t_electronicPayment")}>
              <CheckList.Item value={"recon"}>
                <PaymentMethodOption gateway={"recon"} />
              </CheckList.Item>
            </List>
          </CheckList>
        </div>
      </Stack>
    </>
  );
};

export default PaymentMethodStack;