import i18n from "../i18n";

export const uniformSeasonOptions = [
  {
    label: i18n.t("t_summer"),
    value: '1',
  },
  {
    label: i18n.t("t_winter"),
    value: '2',
  },
]

export const UniformSeason = {
  'S': { 
    label: i18n.t("t_summer"), 
    backgroundColor: "var(--app-season-summer-color)"
  },
  'W': { 
    label: i18n.t("t_winter"), 
    backgroundColor: "var(--app-season-winter-color)"
  },
}