import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Stack from "../Stack";
import styles from "./ShopInformationStack.module.scss"
import testShop from "../../../assets/images/testShop.png"
import { useSelector } from "react-redux";

const ShopInformationStack = ({
  title = "尖沙咀分店",
  shopInfo
}) => {
  const { t } = useTranslation();
  const stackStatus = useSelector((state) => state.tickStack.status);

  return (
    <>
      <Stack
        title={title}
        visible={stackStatus.shopInformation}
      >
        <div className={styles.container}>
          <div className={styles.shopImg}>
            <img src={testShop} alt="shop" width={"100%"} />
          </div>
          <div className={styles.shopAddress}>
            <div>
              Shop OT G29, Ocean Terminal,
              Harbour City, Tsim Sha Tsui
            </div>
            <div>Tel : (852) 3105 0809</div>
          </div>
        </div>
      </Stack>
    </>
  );
};

export default ShopInformationStack;