import React, { useEffect, useState } from 'react';
import { ThemeService } from '../../service/apis/mock/theme/ThemeService';
import { ThemeMap } from './ThemeMap';

export const setColorTheme = async (payload) => {
  const res = await ThemeService.get();
  if (res.length <= 0) alert("Failed to fetch color theme")
    
  let theme = res[0];
  if (payload) {
    theme = payload;
  } else {
    theme = res[0]; // MockApi
  }
  document.getElementById('theme-css')?.remove();

  const styles = theme;
  let admCss = "";

  // Process ant design mobile css variables to string & construct the override css object
  for (let [key, value] of Object.entries(styles)) {
    if (!ThemeMap[key]) continue;
    admCss += `${ThemeMap[key]}: ${value};`
  }

  var style = document.createElement('style');
  style.id = "theme-css"
  style.type = 'text/css';
  style.innerHTML = `:root {${admCss}}`;
  document.getElementsByTagName('head')[0].appendChild(style);

}

const Theme = ({ children }) => {

  const messageHandler = (e) => {
    // TODO: Add checking when sender origin isn't the backstage origin
    // if (e.origin !== "")
      if (!e.data.schoolCode) return;
    console.log('Received message from parent:', e.data);
    document.getElementById('theme-css')?.remove();
    
    const styles = e.data;
    let admCss = "";
    for (let [key, value] of Object.entries(styles)) {
      if (!ThemeMap[key]) continue;
      admCss += `${ThemeMap[key]}: ${value};`
    }

    var style = document.createElement('style');
    style.id = "theme-css"
    style.type = 'text/css';
    style.innerHTML = `:root {${admCss}}`;
    document.getElementsByTagName('head')[0].appendChild(style);

  }

  useEffect(() => {
    // Set up event listener for iframe preview
    window.addEventListener("message", messageHandler)

    // Set color theme through API
    setColorTheme();
    return () => { window.removeEventListener("message", messageHandler) }
  }, [])

  return ( children )
}

export default Theme;