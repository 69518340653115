import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTitle, setUniformPageParam } from '../../redux/tickPageSlice';
import { useTranslation } from 'react-i18next';
import School from "../../assets/images/school.png";
import styles from "./Uniform.module.scss"
import { Helper } from '../../utils/Helper';
import { NavBar, Space, Tabs, Swiper, Modal } from 'antd-mobile';
import CustomArrowButton from '../../components/arrowBtn/CustomArrowButton';
import UniformFilterStack from '../../components/stack/uniformFilter/UniformFilterStack';
import { Gender } from '../../data/genderOptions';
import { UniformSeason } from '../../data/uniformSeasonOptions';
import FilterTag from '../../components/tag/filter/FilterTag';
import Menu from '../../components/menu/Menu';
import UniformSquareButton from '../../components/uniformSquareButton/UniformSquareButton';
import ChooseUniformStack from '../../components/stack/chooseUniform/ChooseUniformStack';
import { LocalStorage } from '../../utils/LocalStorage';
import { Log } from '../../utils/Log';
import { ItemSetService } from '../../service/apis/ItemSetService';
import Response from '../../utils/Response';
import { setData } from '../../redux/tickTempUniformSet';
import { AuthService } from '../../service/apis/AuthService';
import { push } from '../../redux/tickStackSlice';
import RecommendationStack from '../../components/stack/recommendation/RecommendationStack';

const TAG = "Uniform";

const Uniform = () => {
  const entryPageState = useSelector((state) => state.tickPage.params.entryPage);
  const uniformPageState = useSelector((state) => state.tickPage.params.uniformPage);
  const stackStatus = useSelector((state) => state.tickStack.status);
  const dispatch = useDispatch();
  const { t } = useTranslation()

  // const [uniformFilterVisible, setUniformFilterVisible] = useState(false);
  // const [chooseUniformVisible, setChooseUniformVisible] = useState(false);
  const [itemSets, setItemSets] = useState([]);
  const [username, setUsername] = useState("")
  const [educationCode, setEducationCode] = useState("")

  const student = LocalStorage.get("student").value
  const school = LocalStorage.get("school").toObject();
  const popupState = LocalStorage.get("popupState").toObject()
  const education = school.education;
  const genders = school.gender;
  const seasons = school.season;
  const images = school.images;
  const popupText = school.popup_text
  const popupActive = school.popup_active

  const fetchItemSets = async () => {
    const res = await ItemSetService.get();
    new Response(res).handle((data) => {
      const dataWithFilterKeys = Helper.addFilterKeys(data);
      LocalStorage.set("itemSets", JSON.stringify(dataWithFilterKeys));
      const filteredData = Helper.filteredData(dataWithFilterKeys, uniformPageState.gender, uniformPageState.season, uniformPageState.education);
      setItemSets(filteredData)
    })
  }

  const fetchUser = async () => {
    const { success, data } = await AuthService.user();
    if (success) {
      const { student, name } = data;
      // const grade = Helper.getGradingCategoryByGradeKey(student.student_grade);
      const studentGender = [student.student_gender];
      const title = name ? { name: "t_greeting", param: { name } } : "uniform";

      // setEducationCode(grade.education_code);
      setUsername(name);
      dispatch(
        setUniformPageParam({
          gender: studentGender,
          // education: grade.education_code,
          season: undefined,
        })
      );
      dispatch(setTitle(title));
    }
  }

  const getLocalStudent = () => {
    if (!student) return;
    setUsername(student.name)
    setEducationCode(student.studentClass?.["education_code"])
    let title = student.name ? { name: "t_greeting", param: { name: student.name } } : "uniform";
    dispatch(
      setUniformPageParam({
        gender: student.studentGender,
        education: student.studentClass?.["education_code"] || education[0].code,
        season: undefined,
      })
    )
    dispatch(setTitle(title))
  }
  
  const onFilterTabChange = (key) => {
    dispatch(setUniformPageParam({ education: key }));
  }

  useEffect(() => {
    if (LocalStorage.get("token").isNull()) {
      getLocalStudent()
    } else if (!LocalStorage.get("student").isNull()) {
      getLocalStudent()
    } else {
      fetchUser()
    }
  }, [])

  useEffect(() => {
    // if (uniformPageState.education === null) return;
    fetchItemSets();
  }, [uniformPageState])


  useEffect(() => {
    if (popupState === 1 && popupActive === "1" && window.location.pathname === "/main/uniform") {
      Modal.alert({
        text: t("t_paymentReminderConfirm"),
        title: t("t_hint"),
        content: (
          <div dangerouslySetInnerHTML={{ __html: popupText }} />
        ),
      })
      LocalStorage.set("popupState", 0)
    }
  }, [])

  return (
    <>
      <div className="top">
        <NavBar className="nav" backArrow={false} right={<Menu />}>
          {t("t_greeting", { name: username })}
        </NavBar>
      </div>
      <div className="body">
        <div className={styles.uniformPageContainer}>
          <div style={{ width: "100%", height: "250px" }} >
            <Swiper autoplay loop autoplayInterval={5000}>
              {
                images.map((img, index) =>
                  <Swiper.Item key={index}>
                    <img src={img} alt="school-image" style={{ width: "100%", height: "250px" }} />
                  </Swiper.Item>
                )
              }
            </Swiper>
          </div>

          {/* {
            educationCode && */}
          <Tabs
            className={styles.uniformEducationTabsContainer}
            defaultActiveKey={"SH"}
            onChange={onFilterTabChange}
          >
            {
              education.map((o, i) => {
                return (
                  <Tabs.Tab title={o[Helper.toLangKey("name")]} key={o.code} />
                )
              })
            }
          </Tabs>
          {/* } */}
          <CustomArrowButton
            onClick={(e) => {
              // setUniformFilterVisible(true)
              dispatch(push("uniformFilter"))
            }}
          >
            <Space direction='horizontal'>
              {
                (
                  (!uniformPageState.gender || uniformPageState?.gender?.length <= 0) &&
                  (!uniformPageState.season || uniformPageState?.season?.length <= 0)) // If no filtering
                && <p>{t("t_filter")}</p>
              }
              {
                uniformPageState?.gender?.map(
                  (o, i) => 
                    <FilterTag
                      key={`gender_${i}`}
                      backgroundColor={Gender[o].backgroundColor}
                      onCancel={() => {
                        const gender = [...uniformPageState?.gender];
                        gender.splice(gender.indexOf(o), 1)
                        dispatch(setUniformPageParam({ gender }))
                      }}
                    >
                      {genders[o][Helper.toLangKey("name")]}
                    </FilterTag>
                )
              }
              {
                uniformPageState?.season?.map(
                  (o, i) =>
                    <FilterTag
                      key={`season_${i}`}
                      backgroundColor={UniformSeason[o].backgroundColor}
                      onCancel={() => {
                        const season = [...uniformPageState?.season];
                        season.splice(season.indexOf(o), 1)
                        dispatch(setUniformPageParam({ season }))
                      }}
                    >
                      {seasons[o][Helper.toLangKey("name")]}
                    </FilterTag>
                )
              }
            </Space>
          </CustomArrowButton>
          <div className={styles.uniformSetChoiceContainer} style={{ width: '100%' }}>
            {
              itemSets.map((item, index) => {
                return (
                  <UniformSquareButton
                    key={item.code}
                    title={item[Helper.toLangKey("name")]}
                    image={item.images}
                    onClick={() => {
                      // setChooseUniformVisible(true);
                      dispatch(push("chooseUniform"))
                      dispatch(setData(item));
                    }}
                  />
                )
              })
            }
          </div>
        </div>
        <UniformFilterStack
          visible={stackStatus.uniformFilter}
        // setVisible={setUniformFilterVisible} 
        />
        <ChooseUniformStack
          visible={stackStatus.chooseUniform}
        // setVisible={setChooseUniformVisible} 
        />
        <RecommendationStack />
      </div>

    </>
  )
}

export default Uniform